<template>
  <div id="artificial-inteligence" class="widget">
    <div class="draggable">
      <div class="ai-top">
        <h4 class="ai-title">Inteligencia artificial</h4>
        <div class="ai-top-box">
          <a class="portfolio-index" :href="gd_url">GD</a>
          <a class="ai-text" href="/app/artificial-inteligence"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="align-button-text">
      <Button
        @_emit_method_button="_goToTest()"
        :button_name="button_name"
        :button_key="button_key"
        :button_icon="button_icon"
        :button_icon_permission="false"
        :button_icon_id="button_icon_id"
      />
    </div>

    <div v-if="this.data" class="widgets-container">
      <TableCustom :table_header="table_header" :table_body="table_body" />
    </div>
    <div v-else class="widgets-container">
      <p>
        Necesitas completar el test para poder ver resultados en esta
        herramienta.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import TableCustom from "@/components/helpers/Table_custom.vue";
import Button from "@/components/helpers/Button.vue";

export default {
  mixins: [APICaller],
  name: "ArtificialIntelligence",
  components: {
    TableCustom,
    Button,
  },
  data() {
    return {
      gd_url: "",
      table_header: ["Ticker", "Entry", "Compatibility"],
      table_body: [],

      button_name: "Hacer el test",
      button_key: "widget_ai",
      button_icon: "",
      button_icon_id: "widget_ai",
      widget_name: {
        id: "widget-artificial-intelligence",
        name: "Inteligencia artificial",
      },
      data: false,

      url_iframe:
        "https://player.vimeo.com/video/211289739?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre inteligencia artificial",
    };
  },
  mounted() {
    this.gd_url =
      this.$detectEnvironment() + "/app/graficador-dinamico/compatibility";

    this._getAI();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    _getAI() {
      let success = (response) => {
        var ia = response.data;
        if (Array.isArray(ia)) {
          ia.splice(0, 1);
        }
        if (ia != []) {
          this.data = true;
          Object.entries(ia).forEach((e) => {
            let obj = {};
            if (e[1].stock && e[1].tipus_entrada) {
              obj["Ticker"] = e[1].stock["ticker"];
              obj["Entry"] = e[1].tipus_entrada;
              obj["Compatibility"] = e[1].compatibility;
              this.table_body.push(obj);
            }
          });
        }
      };

      let url = "/api/v1/widgets/get-artificial-intelligence";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "138",
        "Error cargando inteligencia artificial."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _goToTest() {
      window.open(process.env.VUE_APP_API + "/test-graficos-potenciales/start");
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.ai-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ai-top-box {
  display: flex;
  align-items: center;
}

.material-icons:hover {
  cursor: pointer;
}

.material-icons {
  margin-left: 5px;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.ai-text {
  font-size: 14px;
  line-height: 22px;
}

.align-button-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.draggable {
  cursor: move;
}
</style>
