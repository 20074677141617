<template>
  <div id="last-ipos" class="widget">
    <div class="draggable">
      <div class="last-ipos-top">
        <h4 class="last-ipos-title">Ultimas IPOs Eurekers</h4>
        <div class="last-ipos-top-box">
          <a class="last-ipos" :href="gd_url">GD</a>
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="widgets-container">
      <TableCustom :table_header="table_header" :table_body="table_body" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import TableCustom from "@/components/helpers/Table_custom.vue";

export default {
  mixins: [APICaller],
  name: "LastIPOs",
  components: {
    TableCustom,
  },
  data() {
    return {
      gd_url: "",
      table_header: [
        "Ticker",
        "Fecha de inicio",
        "Rentab.",
        "Tipo de entrada",
        "Volumen",
      ],
      table_body: [],
      widget_name: { id: "widget-last-ipos", name: "Últimas IPOs Eurekers" },

      url_iframe:
        "https://player.vimeo.com/video/187305383?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre últimas IPOs",
    };
  },
  mounted() {
    this.gd_url =
      this.$detectEnvironment() + "/app/graficador-dinamico/last-ipos";

    setTimeout(() => {
      this._getLastIPOs();
    }, 100);
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getLastIPOs() {
      let success = (response) => {
        let datos = response.data;
        Object.entries(datos).forEach((e) => {
          let obj = {};
          if (e[1].tae_lt > 1000) {
            e[1].tae_lt = e[1].tae_lt.toFixed(0);
          } else {
            e[1].tae_lt = e[1].tae_lt.toFixed(2);
          }
          //e[1].volum = parseFloat(e[1].volum.toFixed(0)).toLocaleString('en');
          if (e[1].nom == "---") {
            obj["ticker"] = e[1].nom;
          } else {
            obj["ticker"] = e[1].ticker;
          }
          obj["b"] = e[1].fecha_primera_barra.replace(
            /^(\d{4})-(\d{2})-(\d{2})$/g,
            "$3/$2/$1"
          );
          obj["c"] = e[1].tae_lt + " %";
          obj["d"] = e[1].entry_type_name;
          obj["e"] = e[1].volum.toFixed(2);
          this.table_body.push(obj);
        });
      };

      let url = "/api/v1/stock-finder/last-ipos";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "145",
        "Error cargando últimas IPOs."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}
.widget:hover {
  border-top: 2px solid #00add8;
}

.last-ipos-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.last-ipos-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.last-ipos-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
