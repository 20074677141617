<template>
  <div id="master-eurekers" class="widget">
    <div class="draggable">
      <div class="master-eurekers-top">
        <h4 class="master-eurekers-title">¿Eres un máster Eurekers?</h4>
        <div class="master-eurekers-top-box">
          <a class="curve-capital" href="/app/exercises"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>

    <div class="master-eurekers-content widgets-container">
      <div class="master-eurekers-content-right">
        <p>Tu nivel es:</p>
        <p class="level-eurekers">{{ this.exercise_level }}%</p>
        <!-- <img src="#" alt="img"> -->
        <img
          v-if="this.exercise_level <= 5"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel0.gif"
        />
        <img
          v-else-if="this.exercise_level > 5 && this.exercise_level <= 25"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel1.gif"
        />
        <img
          v-else-if="this.exercise_level > 25 && this.exercise_level <= 50"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel2.gif"
        />
        <img
          v-else-if="this.exercise_level > 50 && this.exercise_level <= 75"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel3.gif"
        />
        <img
          v-else-if="this.exercise_level > 75 && this.exercise_level <= 95"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel4.gif"
        />
        <img
          v-else-if="this.exercise_level > 95 && this.exercise_level <= 100"
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel5.gif"
        />
        <img
          v-else
          src="https://eurekers.com/sites/all/themes/Porto_sub/widgets/trebol-master-eurekers-nivel5.gif"
        />
      </div>
      <div
        class="master-eurekers-content-left"
        style="text-align: left; width: 200px; margin-left: 10px;"
      >
        <div id="basic-btn-container" class="basic-btn-container">
          <span id="span-basic-btn" class="span-basic-btn">&#187;</span
          ><button
            id="basic-btn"
            class="basic-btn"
            @click="_showBasicExercises()"
          >
            Ejercicios básicos
          </button>
        </div>

        <div
          v-if="this.basic_exercises"
          id="basic-exercises-container"
          class="basic-exercises-container"
        >
          <div
            v-for="basic_ex in basic_exer"
            :key="basic_ex"
            id="basic_exercises"
            class="basic_exercises"
          >
            <a :href="link_to_exercise" class="b_url">{{
              "·" + basic_ex.exercise_type + ": " + basic_ex.walkaround
            }}</a>
            <div
              class="main-progress-bar back-progress-bar"
              style="width: 100%"
            ></div>
            <div
              class="main-progress-bar front-progress-bar"
              :style="{ width: level_info_advanced.level + '%' }"
            ></div>
          </div>
        </div>

        <div
          role="progressbar"
          class="mdc-linear-progress"
          :class="this.key_linear_basic"
          aria-label="Example Progress Bar"
          aria-valuemin="0"
          aria-valuemax="1"
          aria-valuenow="0"
          style="margin-top:10px;"
        >
          <div
            class="main-progress-bar back-progress-bar"
            style="width: 100%"
          ></div>
          <div
            class="main-progress-bar front-progress-bar"
            :style="{ width: level_info_basic.level + '%' }"
          ></div>
        </div>
        <div id="advanced-btn-container" class="advanced-btn-container">
          <span id="span-advanced-btn" class="span-advanced-btn">&#187;</span
          ><button
            id="advanced-btn"
            class="advanced-btn"
            @click="_showAdvancedExercises()"
          >
            Ejercicios avanzados
          </button>
        </div>

        <div
          v-if="this.advanced_exercises"
          id="advanced-exercises-container"
          class="advanced-exercises-container"
        >
          <div
            v-for="advanced_ex in advanced_exer"
            :key="advanced_ex"
            id="advanced_exercises"
            class="advanced_exercises"
          >
            <a :href="link_to_exercise" class="a_url">{{
              "·" + advanced_ex.exercise_type + ": " + advanced_ex.walkaround
            }}</a>
          </div>
        </div>

        <div
          role="progressbar"
          class="mdc-linear-progress"
          :class="this.key_linear_advanced"
          aria-label="Example Progress Bar"
          aria-valuemin="0"
          aria-valuemax="1"
          aria-valuenow="0"
          style="margin-top:10px;"
        >
          <div
            class="main-progress-bar back-progress-bar"
            style="width: 100%"
          ></div>
          <div
            class="main-progress-bar front-progress-bar"
            :style="{ width: level_info_advanced.level + '%' }"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: "MasterEurekers",
  components: {},
  data() {
    return {
      card_progress_value_basic: 18.5,
      card_progress_value_advanced: 0,
      exercise_level: 0,
      basic_exer: [],
      basic_exercises: false,
      advanced_exer: [],
      advanced_exercises: false,
      key_linear_basic: "basic",
      key_linear_advanced: "advanced",
      widget_name: { id: "widget-master-eurekers", name: "Master Eurekers" },
      master_eurekers_info: 0,
      basic_walkarounds_split: [],
      advanced_walkarounds_split: [],
      basic_divisions: [],
      advanced_divisions: [],
      level_info_basic: "",
      level_info_advanced: "",
      link_to_exercise: "",
      url_iframe:
        "https://player.vimeo.com/video/187305097?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre máster Eurekers",
    };
  },
  created() {
    this.master_eurekers_info =
      (this.card_progress_value_basic + this.card_progress_value_advanced) / 2;
  },
  mounted() {
    this.link_to_exercise = this.$detectEnvironment() + "/app/exercises";

    this._getMasterEurekeres();

    var level_eurekers = setInterval(() => {
      if (this.level_info_basic != "") {
        clearInterval(level_eurekers);
      }
    }, 200);
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getMasterEurekeres() {
      let success = (response) => {
        this.basic_exer = response.data.exers_info.basic;
        this.advanced_exer = response.data.exers_info.advanced;
        this.level_info_basic = response.data.level_info.exercises_basic;
        this.level_info_advanced = response.data.level_info.exercises_advanced;

        for (var i = 0; i < this.basic_exer.length; i++) {
          this.basic_walkarounds_split.push(
            this.basic_exer[i].walkaround.split("/")
          );
        }

        for (var j = 0; j < this.advanced_exer.length; j++) {
          this.advanced_walkarounds_split.push(
            this.advanced_exer[j].walkaround.split("/")
          );
        }

        this._level_user();
      };

      let url = "/api/v1/widgets/get-master-eurekers";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "147",
        "Error recuperando masterclass."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },

    _level_user: function() {
      this.exercise_level = (
        this.level_info_basic.level * this.level_info_basic.factor +
        this.level_info_advanced.level * this.level_info_advanced.factor
      ).toFixed(2);
    },

    _showAdvancedExercises() {
      this.advanced_exercises = !this.advanced_exercises;
      this.basic_exercises = false;
    },

    _showBasicExercises() {
      this.basic_exercises = !this.basic_exercises;
      this.advanced_exercises = false;
    },

    _exercises_level() {
      var n_basic_questions = 12;
      var n_advanced_questions = 5;

      for (var i = 0; i < this.basic_walkarounds_split.length; i++) {
        var element_i = this.basic_walkarounds_split[i];
        var div_i = element_i[0] / element_i[1];
        this.basic_divisions.push(div_i);
      }

      for (var j = 0; j < this.advanced_walkarounds_split.length; j++) {
        var element_j = this.advanced_walkarounds_split[j];
        var div_j = element_j[0] / element_j[1];
        this.advanced_divisions.push(div_j);
      }

      this._helper_operator(n_basic_questions, n_advanced_questions);
    },

    _helper_operator(n_basic, n_advanced) {
      var suma_basic = 0;
      var suma_advanced = 0;

      for (var i = 0; i < this.basic_divisions.length; i++) {
        suma_basic += this.basic_divisions[i] / n_basic;
      }

      suma_basic = suma_basic * 0.3;

      for (var j = 0; j < this.advanced_divisions.length; j++) {
        suma_advanced += this.advanced_divisions[j] / n_advanced;
      }

      suma_advanced = suma_advanced * 0.7;

      this.exercise_level =
        Math.round(
          ((suma_basic * n_basic * 100 + suma_advanced * n_advanced * 100) /
            (n_basic + n_advanced) +
            Number.EPSILON) *
            100
        ) / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@material/linear-progress";
@include linear-progress.core-styles;
.master-eurekers-top {
  display: flex;
  align-items: center;
}
.main-progress-bar {
  height: 5px;
  position: absolute;
  top: 0;
  left: 0;
}

.back-progress-bar {
  background: grey;
}
.front-progress-bar {
  background: #0797b9;
}

.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.master-eurekers-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.master-eurekers-text {
  font-size: 14px;
  line-height: 22px;
}

.master-eurekers-content {
  display: flex;
}
.draggable {
  cursor: move;
}
.level-eurekers {
  color: #00add8;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 18px;
  margin: 0 0 14px;
  text-transform: uppercase;
}

.basic-btn-container {
  margin-top: 15px;
  margin-right: 10px;
}

.advanced-btn-container {
  margin-top: 10px;
}

.basic-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #00add8;
}

.advanced-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #00add8;
}

.basic-btn:hover {
  color: #00c5f5;
}

.advanced-btn:hover {
  color: #00c5f5;
}

.basic-exercises-container {
  z-index: 99999;
  background-color: white;
  width: 200px;
  overflow: scroll;
  height: 180px;
}

.advanced-exercises-container {
  z-index: 999999;
  background-color: white;
  width: 200px;
}

.b_url {
  font-size: 12px;
  margin-left: 10px;
}

.b_url:hover {
  color: #25d4ff;
}

.a_url {
  font-size: 12px;
  margin-left: 10px;
}

.a_url:hover {
  color: #25d4ff;
}

hr {
  border: 1px solid #00c5f5;
  border-top: none;
  width: 200px;
  margin-left: 10px;
}
</style>
