<template>
  <div id="portfolio-risk" class="widget">
    <div id="main-container">
      <div class="draggable">
        <div class="portfolio-risk-top">
          <h4 class="portfolio-risk-title">Riesgo de cartera</h4>
          <div class="portfolio-risk-top-box">
            <a class="curve-capital" href="/app/portfolio"
              ><span class="material-icons blue">launch</span></a
            >
            <span class="material-icons blue" @click="_sendDataToIframe()"
              >help</span
            >
            <span @click="_closeWidget()" class="material-icons">close</span>
          </div>
        </div>
      </div>

      <div v-if="loading"></div>
      <div v-else>
        <SelectOutline
          v-if="this.portfolio_selected[0] != undefined"
          :helper_select_permission="false"
          :label_name="portfolioJson.label_name"
          :options="portfolio_list"
          :component_class="component_classs"
          :helper_text_select="portfolioJson.helper_text_select"
          :default_value="this.portfolio_selected[0].name"
          @_emit_method_order_select="_selectValue"
        />
      </div>
      <div class="simulador-highchart widgets-container">
        <div id="highchart-content-portfolio-risk" style="height:200px"></div>
        <div id="risk-tip">{{ this.risk_tip }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import PortfolioList from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import Highcharts from "highcharts";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioRisk",
  components: {
    SelectOutline,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [{ name: "default", value: "Default" }],
      component_class: "portfolio_risk",
      helper_text_select: "",
      default_value: "default",
      value_selected: "",
      portfolioJson: PortfolioList,
      component_classs: "portfolio_risk",
      portfolio_list: [],
      portfolio_selected: [],
      loading: true,
      risk_tip: "",
      series: [
        {
          name: "Riesgo alcista ",
          data: [],
          color: "#00add8",
        },
        {
          name: "Riesgo bajista  ",
          data: [],
          color: "#444242",
        },
      ],
      widget_name: { id: "widget-portfolio-risk", name: "Riesgo de cartera" },

      url_iframe:
        "https://player.vimeo.com/video/187303991?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre riesgo de cartera",
    };
  },
  created() {
    this._getPortfolios();
    this._defaultPortfolio();

    var risk = setInterval(() => {
      if (this.value_selected != "" && this.portfolio_list != []) {
        this._getPorfolioRisk();
        this._getRiskTip();
        this.loading = false;
        clearInterval(risk);
      }
    }, 100);
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getPorfolioRisk() {
      let success = (response) => {
        // this.series = response.data
        this.series[0].data = [];
        this.series[1].data = [];
        this.series[0].name = "Riesgo alcista " + response.data[0].data[0].y;
        this.series[1].name = "Riesgo bajista " + response.data[1].data[0].y;
        this.series[0].data.push(response.data[0].data[0].y);
        this.series[1].data.push(response.data[1].data[0].y);
        var addDataPortfolioRiskInterval = setInterval(() => {
          if(document.getElementById('highchart-content-portfolio-risk') != null || document.getElementById('highchart-content-portfolio-risk') != undefined){
            this._addDataPorfolioRiskToHighchart();
            clearInterval(addDataPortfolioRiskInterval);
          }
        }, 100);
      };

      let url = "/api/v1/portfolio/get-risk/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "156",
          "Error recuperando riesgo de cartera."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },

    _defaultPortfolio: function() {
      let success = (response) => {
        this.portfolio_selected.push({
          name: response.data.idc,
          value: response.data.name,
        });
        this.value_selected = response.data.idc;
      };

      let url = "/api/v1/portfolio-invest-preferences";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "157",
        "Error recuperando preferencias de inversión."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _getRiskTip: function() {
      let success = (response) => {
        this.risk_tip = response.data.message;
        document.getElementById("risk-tip").style.color = response.data.color;
      };

      let url = "/api/v1/widgets/get-risk-tip/" + this.value_selected;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "158",
        "Error recuperando perfil de riesgo."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _addDataPorfolioRiskToHighchart: function() {
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart("highchart-content-portfolio-risk", {
          chart: {
            type: "column",
          },
          title: {
            text: "",
          },
          subtitle: {
            text: "Eurekers",
          },
          xAxis: {
            categories: [""],
            crosshair: true,
          },
          yAxis: {
            min: 0,
            max: Math.max(self.series[0].data, self.series[1].data, 8),
            title: {
              text: "Riesgo (%)",
            },
            plotLines: [
              {
                value: 6,
                color: "#57c6f9",
                dashStyle: "shortdash",
                width: 2,
                label: {
                  text: "6 %",
                },
              },
            ],
          },
          tooltip: {
            headerFormat:
              '<span style="font-size:10px">{point.key}</span><table>',
            pointFormat:
              '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
              '<td style="padding:0"><b>{point.y:.1f} </b></td></tr>',
            footerFormat: "</table>",
            shared: true,
            useHTML: true,
          },
          plotOptions: {
            column: {
              pointPadding: 0.2,
              borderWidth: 0,
            },
          },
          series: self.series,
        });
      });
    },

    _selectValue(value) {
      this.value_selected = value[0];
      this._getPorfolioRisk();
      this._getRiskTip();
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.portfolio-risk-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-risk-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

#highchart-content-portfolio-risk {
  width: 100%;
  height: 220px;
}
.draggable {
  cursor: move;
}
#risk-tip {
  font-size: 10px;
  margin-top: 5px;
  line-height: 12px;
  text-align: left;
}
</style>
