<template>
  <div id="your-photo" class="widget">
    <div class="draggable">
      <div class="your-photo-top">
        <h4 class="your-photo-title">Tu foto</h4>
        <div class="your-photo-top-box">
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div v-if="photo_url!='Todavía no hay ninguna foto tuya con Jose Antonio Madrigal'" class="video-background">
      <div class="video-foreground">
        <img class="video-class" v-bind:src="photo_url">
      </div>
    </div>
    <div v-else class="widgets-container">
      <p>Hola <b>{{username}}</b></p>
      <h3>NO TIENES UNA FOTO CON EL PROFESOR EUREKERS</h3>
      <p>¿Tienes alguna duda sobre tu foto? <a target="_blank" href="https://www.eurekers.com/contacta-con-nosotros">Envianos tus preguntas</a></p>
    </div>
  </div>
</template>


<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
export default {
  mixins: [APICaller],
  name: 'YourPhoto',
  data() {
    return {
      //src: "https://static.eldiario.es/clip/1984887a-773c-480b-95ce-9a8b4d59f08c_16-9-aspect-ratio_default_0.jpg",
      src: "",
      username: "",
      photo_url: "",
      widget_name: {id: "widget-your-photo", name: "Tu foto"} 
    };
  },
  mounted() {

    this._getYourPhoto();
    
  },
  computed:{
    ...mapGetters(['is_mobile_device']),
  },
  methods: {
    _getYourPhoto() {
      let success = response => {
        this.photo_url = response.data.photo_url;
        this.username = response.data.username;
      }

      let url = '/api/v1/widgets/get-your-photo';
                  
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('163', 'Error recuperando foto evento.');
      const apiCallConfig = {loadingOverlay: false};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function(){
      this.$emit("widget_name", this.widget_name);
    },
  }
}
</script>



<style lang="scss" scoped>

.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

    .video-class{
      height: 280px;
      width: 400px;
      overflow-x: hidden;
    }

    .widget:hover{
      border-top: 2px solid #00add8;
    }

    .your-photo-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .your-photo-top-box {
        display: flex;
        align-items: center;
    }

    .material-icons {
        margin-left: 5px;
    }
    .material-icons:hover{
        cursor: pointer;
    }

    h4 {
        color: #00add8;
    }

    a {
        text-decoration: none;
        color: #00add8;
        font-weight: bold;
    }

    .blue {
        color: #00add8;
    }

    .blue:hover{
        border-bottom:1px solid #00add8;
    }

    .your-photo-text {
        font-size: 14px;
        line-height: 22px;
    }
    .draggable{
      cursor: move;
    }

    h3{
      color: #00add8;
    }
</style>
