<template>
  <div class="table-custom table-custom">
    <div>
      <table>
        <thead class="top-table">
          <tr>
            <th v-for="(item, index) in table_header" :key="index" role="columnheader" data-column-id="date" scope="col" class="header-table">{{item}}</th>
          </tr>
        </thead>
        <tbody >
          <tr v-for="(row, index) in table_body" :key="index">
            <th v-for="(cel, key) in row" :key='key' scope="row" class="table-row">
              <span v-if="_g_User.is_pre_eureker_user && cel == '---'" class="mdc-list-item__text typo-body-2" >
                <span @click="preEurekersWarning()" class="material-icons-outlined">visibility</span>
              </span>
              <span v-else>{{cel}}</span>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>



<script>
  import TableCustom from "@/components/helpers/Table_custom.vue";
  import Vue from 'vue';
  import { mapGetters } from "vuex";

  export default {
    name: 'TableCustom',
    props: {
      table_header: {type: Array},
      table_body: {type: Array},
      table_obj: {type: Object}
    },
    data() {
      return {
        link_to_store: "",
      };
    },
    computed: {
      ...mapGetters(["_g_User"]),
    },
    mounted() {
      this.link_to_store = this.$detectEnvironment() +'/app/store';
      const MyComponentConstructor = Vue.extend(TableCustom);
      const table = new MyComponentConstructor();
    },
    methods: {
      preEurekersWarning() {
        let toast = {type: 'info', body: 'Para visualizar valores, acceda al Graficador/Buscador o actualice su suscripción.', clickHandler: function(){
          window.location.replace(this.link_to_store);
        }};
        this.$vueOnToast.pop(toast);
      }
    }
  }
</script>



<style lang="scss">

#stocks{
  .table-custom{
    height: auto;
    width:auto;
    margin-top:25px;
    margin-bottom:50px;
  }
  .header-table{
    margin-right:50px !important;
  }
}
#valores{
  .table-custom{
    height: auto;
    width:100%;
    margin-top:25px;
    margin-bottom:50px;
  }
  table{
    border-spacing: 10px !important;
  }
  .header-table{
    margin-right:50px !important;
  }
}
.header-table{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.top-table{
  width: 100%;
}

.table-custom{
  height: 280px;
  width: 417px;
  background-color: #F3F4F7;
  overflow-y: scroll;
  border: 1px solid lightgrey;
  border-radius: 5px;
}

.table-row{
  font-size: 12px;
  background-color: white;
  font-weight: normal;
}

#best-bullish-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#hot-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
  .top-table{
    width: 100%;
  }
}

#best-sectors{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#portfolio-changes{
  table {
    width: 100%;
  }

  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: auto;
    height: 40px;
    color: var(--color-text-secondary);
    background-color: var(--color-active-surface);
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: var(--color-surface);
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#twin-souls{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 417px;
    height: 40px;
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    height: 180px;
    width: auto;
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
    overflow-y: scroll;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#artificial-inteligence{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 139px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#waiting-values{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}
#open-positions{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: auto;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);

  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}
#last-ipos{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 104px;
    height: 40px;
  }
  .table-custom{
    height: auto;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#best-bullish{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 208.5px;
    height: 40px;
  }
  .table-custom{
    height: 220px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }

  .header-table{
    background-color: var(--color-active-surface);
    color: var(--color-text-secondary);
  }
  .table-custom{
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
  }

  .table-row{
    background-color: var(--color-surface);
    color: var(--color-text-secondary);
  }
}

#fundamental{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }
  .table-custom{
    width: 362px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}

#fundamental-mobile{
  .header-table{
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    width: 357px;
    height: 40px;
  }
  .table-custom{
    width: 332px !important;
    height: 180px;
    width: auto;
    background-color: #F3F4F7;
    overflow-y: scroll;
    border: 1px solid lightgrey;
    border-radius: 5px;
  }

  .table-row{
    font-size: 12px;
    height: 40px;
    background-color: white;
    font-weight: normal;
  }
  .top-table{
    width: 100%;
  }
}
</style>
