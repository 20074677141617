<template>
  <div id="portfolio-index" class="widget">
    <div class="draggable">
      <div class="portfolio-index-top">
        <h4 class="portfolio-index-title">Índice de cartera</h4>
        <div class="portfolio-index-top-box">
          <a class="curve-capital" @click="_redirectToPortfolioPortfolioIndex()"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>

    <div v-if="loading"></div>
    <div v-else>
      <SelectOutline
        v-if="this.portfolio_selected[0] != undefined"
        :helper_select_permission="false"
        :label_name="portfolioJson.label_name"
        :options="portfolio_list"
        :component_class="component_classs"
        :default_value="this.portfolio_selected[0].name"
        :helper_text_select="portfolioJson.helper_text_select"
        @_emit_method_order_select="_selectValue"
      />
    </div>

    <div class="simulador-highchart widgets-container">
      <div id="highchart-content"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import PortfolioList from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import Highcharts from "highcharts";
import Vue from "vue";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioIndex",
  components: {
    SelectOutline,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [{ name: "default", value: "Default" }],
      component_class: "portfolio_index",
      helper_text_select: "",
      default_value: "default",
      portfolioJson: PortfolioList,
      portfolio_list: [],
      portfolio_selected: [],
      value_selected: "",
      series: [
        {
          name: "Indice de cartera",
          data: [0, 0],
        },
      ],
      widget_name: { id: "widget-portfolio-index", name: "Indice de cartera" },
      component_classs: "portfolio_index",
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/387654337?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre índice de cartera",
    };
  },
  created() {
    this._getPortfolios();
    this._defaultPortfolio();

    var index = setInterval(() => {
      if (this.value_selected != "" && this.portfolio_list != []) {
        this._getPorfolioIndex();
        this.loading = false;
        clearInterval(index);
      }
    }, 100);
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _redirectToPortfolioPortfolioIndex: function(){
      window.location.href = "/app/portfolio/" + this.value_selected + "/portfolio-index"
    },
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getPorfolioIndex() {
      let success = (response) => {
        this.series = response.data.series;
        var addDataToHighchartInterval = setInterval(() => {
          if(document.getElementById('highchart-content') != null || document.getElementById('highchart-content') != undefined){
            this._addDataToHighchart();
            clearInterval(addDataToHighchartInterval);
          }
        }, 100);
      };

      let url = "/api/v1/portfolio/get-portfolio-index/" + this.value_selected;
      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "154",
          "Error recuperando índice de cartera."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },
    _defaultPortfolio: function() {
      let success = (response) => {
        this.portfolio_selected.push({
          name: response.data.idc,
          value: response.data.name,
        });
        this.value_selected = response.data.idc;
      };

      let url = "/api/v1/portfolio-invest-preferences";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "155",
        "Error recuperando preferencias de inversión."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _addDataToHighchart: function() {
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart("highchart-content", {
          chart: {
            type: "line",
            zoomType: "x",
            height: 180,
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
              year: "%b",
            },
          },
          yAxis: {
            title: "",
          },
          tooltip: {
            shared: true,
            crosshairs: true,
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
          },
          series: self.series,
        });
      });
    },

    _selectValue(value) {
      this.value_selected = value[0];
      this._getPorfolioIndex();
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.portfolio-index-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-index-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.portfolio-index-text {
  font-size: 14px;
  line-height: 22px;
}

#highchart-content {
  width: 100%;
  height: 220px;
}
.draggable {
  cursor: move;
}
</style>
