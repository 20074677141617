<template>
  <div id="motivational-sentences" class="widget">
    <div class="draggable">
      <div class="motivational-sentences-top">
        <h4 class="motivational-sentences-title">SOLUCIONES EJERCICIOS</h4>
        <div class="motivational-sentences-top-box">
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <a
      href="/sites/default/files/guia_inicio/guia-herramientas-pre-eurekers.pdf"
    >
      <div v-if="is_mobile_device">
        <img
          style="margin-top: -17px;"
          src="/sites/all/themes/Porto_sub/widgets/guia-284x284.gif"
          alt="Imagen widget"
        />
      </div>
      <div v-else>
        <img
          style="margin-top: -14px;"
          src="/sites/all/themes/Porto_sub/widgets/guia-432x286.gif"
          alt="Imagen widget"
        />
      </div>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: "PreEurekersExers",
  components: {},
  data() {
    return {
      motivational_sentence: "",
      widget_name: {
        id: "widget-pre-eurekers-exers",
        name: "Soluciones ejercicios",
      },
    };
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}
.image-class {
  height: 280px;
  width: auto;
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.motivational-sentences-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.motivational-sentences-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.motivational-sentences-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
