<template>
    <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user">
        <div class="container" v-if="_g_UserFeatures.permissions.control_panel_tools">
            <div class="disable-widgets-container">
                <span class="disabled-info" v-if="disabled.length == 0">Todos los widgets activos</span>      
                <span class="widgets-disabled" v-for="(widget, index) in disabled" :key="index"
                @click="_enableWidget(widget.i , index)"
                :data-widget_id="widget.i">{{widget.name}}</span>    
            </div>
            <grid-layout :layout.sync="layout"
                        :col-num="col_num"
                        :row-height="row_height"
                        :is-draggable="draggable"
                        :is-resizable="resizable"
                        :vertical-compact="true"
                        :use-css-transforms="true"
                        @layout-before-mount="_getWidgetsPosition()"
            >
                <grid-item v-for="(item, index) in layout"
                        :key='"grid widgets" + index'
                        :static="item.static"
                        :x="item.x"
                        :y="item.y"
                        :w="item.w"
                        :h="item.h"
                        :i="item.i"
                        @moved="movedEvent"
                >
                    <component  :is="item.i" class="other-widgets" style="" @widget_name="widget_name=$event" @url_iframe="url_iframe=$event" @help_tittle="help_tittle=$event" @load_iframe="load_iframe"></component>
                </grid-item>
            </grid-layout>
            <div v-if="show_help" class="close-div" @click="_closeHelpIframe">
                <div class="help-pop-up">
                    <iframe :src="url_iframe" width="100%" height="344" frameborder="0" allowfullscreen=""></iframe>
                    <span style="color: grey; font-weight: bold">{{help_tittle}}</span>
                    <span class="material-icons-outlined" style="float: right; font-size: 20px; margin-right: 10px; color: grey" @click="_closeHelpIframe()">close</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="_g_User.is_only_authenticated_user" class="not-allowed-widgets-container">
      <p class="not-allowed-widgets-title"> SIN ACCESO </p>
      <p class="not-allowed-widgets"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
    </div>   
    <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
        <div class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
            <img src="@/views/assets/widgets-screenshot.png">
            <div class="text-centeR">
            <h2>Panel de control</h2>
            <p>Controla tus inversiones en todo momento dentro de un mismo espacio de trabajo. Comprueba rápidamente el estado de tu/s cuenta/s, mira tus posiciones, revisa tus stops, anota tus observaciones y planifica tus próximos movimientos en función de tu metodología de inversión.</p>
            </div>
            <div class="button-container">
                <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
            </div>  
        </div>        
    </div>
</template>

<script>
import { GridLayout, GridItem } from "vue-grid-layout";
import widget_platinum_best_values from './widgets/widget_platinium_best_values.vue';
import widget_twin_souls from "./widgets/widget_twin_souls.vue";
import widget_best_bullish_values from "./widgets/widgets_best_bullish_values.vue";
import widget_hot_values from "./widgets/widgets_hot_values.vue";
import widget_forum_search from "./widgets/widget_forum_search.vue";
import widget_alerts from "./widgets/widget_alerts.vue";
import widget_open_positions from "./widgets/widget_open_positions.vue";
import widget_best_sectors from "./widgets/widget_best_sectors.vue";
import widget_portfolio_waiting_values from "./widgets/widget_waiting_values.vue";
import widget_portfolio_changes from "./widgets/widget_portfolio_changes.vue";
import widget_motivational_sentences from "./widgets/widget_motivational_sentences.vue";
import widget_portfolio_index from "./widgets/widget_portfolio_index.vue";
import widget_curve_capital from "./widgets/widget_curve_capital.vue";
import widget_portfolio_risk from "./widgets/widget_portfolio_risk.vue";
import widget_master_eurekers from "./widgets/widget_master_eurekers.vue";
import widget_artificial_intelligence from "./widgets/widget_artificial_intelligence.vue";
import widget_favorite_search from "./widgets/widget_favorite_search.vue";
import widget_last_ipos from "./widgets/widget_last_ipos.vue";
import Add from "./widgets/widget_add.vue";
//import widget_interesting_forums from "./widgets/widget_featured_forums.vue";
//import widget_new_tools from "./widgets/widget_new_tools.vue";
import widget_your_photo from "./widgets/widget_your_photo.vue";
import widget_master_class_overview from "./widgets/widget_master_class_overview.vue";
import widget_directionality from "./widgets/widget_directionality.vue";
import widget_ticker_search from "./widgets/widget_search_by_ticker.vue";
import widget_pre_eurekers_exers from "./widgets/widget_pre_eurekers_exers.vue";
import widget_advanced_course_opinions from "./widgets/widget_opiniones_curso_avanzado.vue";
import widget_why_take_advanced_course from "./widgets/widget_take_advanced_course.vue";
import widget_best_portfolio from "./widgets/widget_best_portfolio.vue";
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters, mapMutations } from "vuex";

export default {
    mixins: [APICaller],
    components: {
        widget_twin_souls,
        widget_platinum_best_values,
        widget_best_bullish_values,
        widget_hot_values,
        widget_best_sectors,
        widget_portfolio_waiting_values,
        widget_portfolio_changes,
        widget_motivational_sentences,
        widget_portfolio_index,
        widget_curve_capital,
        widget_portfolio_risk,
        widget_master_eurekers,
        widget_artificial_intelligence,
        widget_favorite_search ,
        widget_last_ipos,
        widget_forum_search,
        widget_alerts,
        widget_open_positions,
        Add,
        widget_ticker_search,
        //widget_interesting_forums,
        //widget_new_tools,
        widget_your_photo,
        widget_master_class_overview,
        widget_directionality,
        widget_why_take_advanced_course,
        widget_advanced_course_opinions,
        widget_pre_eurekers_exers,
        widget_best_portfolio,
        GridLayout,
        GridItem,
    },
    data() {
        return {
            layout: [
                {"x":3,"y":0,"w":1,"h":6,"i":"Add", static: true},
            ],
            draggable: true,
            resizable: false,
            index: 0,
            col_num: 4,
            row_height: 54,
            screen: window.innerWidth,
            disabled: [],
            widget_name: {},
            show_help: false,
            help_tittle: '',
            url_iframe: '',
            is_basic_user: "",
            user_info_is_loaded: "",
            basic_user_uid:"",
            position_calculated: false,
            static_widgets: window.innerWidth < 901,
        }
    },
    created(){
        if(this._g_IsUserLogged){ 
            setInterval(() => {      
                this.screen = window.innerWidth;
            }, 1000);
        }
        this._getUID()
        
    },
    watch:{
        widget_name: {      
            handler: function () {
            //hacer cambios de enabled true o false y post
                this._manageDisabledWidgets();
            },
        },
        screen:{
            handler: function(){
                this._getWidgetsPosition();
            },
            deep: true
        },
    },
    computed: {
        ...mapGetters(["_g_IsUserLogged", "_g_User", "_g_UserFeatures"]),
    },    
    methods: {
        _redirectToLogin() {
            window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
        },
        _getUID(){
            this.basic_user_uid = this._g_User.uid;
            this.user_info_is_loaded = true;
            this._getUserInfo();
        },
        _getUserInfo(){
            this.is_basic_user = this._g_User.is_basic_user;
            this.user_info_is_loaded = true;
        },
        load_iframe: function(){
            this.show_help = true;
        },
        _closeHelpIframe: function(){
            this.show_help = false;
        },
        itemTitle(item) {
            let result = item.i;
            if (item.static) {
                result += " - Static";
            }
            return result;
        },
        movedEvent: function (){
          this._postWidgetsPosition();        
        },

        _prepareInfoToPost: function(array){
            var arrayP = array;
            var res = {};
            arrayP.sort(function(a, b){
                return (a.x + a.y) - (b.x + b.y);
            });
            let j=0;
            for(let i=0; i < arrayP.length; i++){
                if(arrayP[i].i != 'Add') {
                    res[arrayP[i].i.replaceAll("_","-")] = j;
                    j++;
                }
            }
            return res;
        },

        _enableWidget: function(name, index){
            this._alignWidgets();           
            var element = this.disabled.splice(index,1);
            this.layout.push(element[0]);  
            this._postUpdateConfiguration(name.replaceAll("_","-"), true);


        },

        _positionCalculated() {
            this.position_calculated = true;
            setTimeout(() => {
                this.position_calculated = false;
            }, 2000);
        },

        _manageDisabledWidgets: function(){
            let array =  JSON.parse(JSON.stringify(this.layout));

            const index = array.map(item => item.i).indexOf(this.widget_name.id.replaceAll("-","_"));
            this.layout.splice(index, 1);            
            let name = this._getWidgetName(this.widget_name.id);
            let posicion = {"x": 0,"y": 0,"w":1,"h":6,"i": this.widget_name.id.replaceAll("-","_"), static: this.static_widgets, "name": name};

            this.disabled.push(posicion);

            this._postUpdateConfiguration(this.widget_name.id, false);
        },

        _postUpdateConfiguration: function(name, estado){
            var params = new URLSearchParams();           
            params.append('widget_id', JSON.stringify(name));
            params.append('configuration', JSON.stringify({"enabled": estado}));

            let url = "/api/v1/widgets/update-configuration";
                    
            const successHandler = new APICaller.SuccessHandler();
            const failureHandler = new APICaller.FailureHandler('267', 'Error actualizando la configuración.');
            const apiCallConfig = {loadingOverlay: false};

            this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);

        },

        _postWidgetsPosition: function(){            
            var params = new URLSearchParams();
                      
            params.append('positions', JSON.stringify(this._prepareInfoToPost(JSON.parse(JSON.stringify(this.layout)))));

            let url = "/api/v1/widgets/update-positions";
            
            const successHandler = new APICaller.SuccessHandler();
            const failureHandler = new APICaller.FailureHandler('268', 'Error actualizando posiciones.');
            const apiCallConfig = {loadingOverlay: false};

            this._postAPICall(url, params, successHandler, failureHandler, apiCallConfig);

        },

        _getNumRows: function(){
            if(this.screen <= 960){
                    this.col_num = 1;
                    this.layout = [
                        {"x":0,"y":0,"w":1,"h":6,"i":"Add", static: true},
                    ];
                }
                else if(this.screen <= 1400){
                    this.col_num = 2;
                    this.layout = [
                        {"x":1,"y":0,"w":1,"h":6,"i":"Add", static: true},
                    ];
                }
                else if(this.screen <= 1830){
                    this.col_num = 3;
                    this.layout = [
                        {"x":2,"y":0,"w":1,"h":6,"i":"Add", static: true},
                    ];
                }
                else{
                    this.col_num = 4;
                    this.layout = [
                        {"x":3,"y":0,"w":1,"h":6,"i":"Add", static: true},
                    ];
                }
        },

        _getWidgetName: function(id){
            let name = "";
            let widgets_names = {
                'widget-best-sectors' : 'Mejores sectores',
                'widget-curve-capital' : 'Curva de capital',
                'widget-new-tools' : 'Nuevas mejoras',
                'widget-platinum-values' : 'Valores Platino',
                'widget-portfolio-risk' : 'Riesgo de cartera',
                'widget-portfolio-waiting-values' : 'Valores en espera',
                'widget-hot-values' : 'Más comentados',
                'widget-directionality' : 'Direccionalidad',
                'widget-last-ipos' : 'Últimas IPOs',
                'widget-master-eurekers' : 'Master Eurekers',
                'widget-best-bullish-values': 'Mejores valores alcistas',
                'widget-your-photo': 'Tu foto',
                'widget-favorite-search': 'Búsquedas favoritas',
                'widget-twin-souls': 'Almas gemelas',
                'widget-artificial-inteligence': 'Inteligencia artificial',
                'widget-portfolio-index': 'Índice de cartera',
                'widget-interesting-forums': 'Foros destacados',
                'widget-motivational-sentences': 'Citas célebres',
                'widget-master-class-overview': 'Resumen de emisiones en directo',
                'widget-ticker-search': 'Búsqueda de ticker',
                'widget-advanced-course-opinions': 'Opiniones de curso avanzado',
                'widget-why-take-advance-course': 'Por qué hacer el curso avanzado',
                'widget-forum-search': 'Búsquedas de foro',
                'widget-pre-eurekers-exers': 'Soluciones ejercicios',
                'widget-alerts': 'Alertas',
                'widget-open-positions': 'Posiciones abiertas',
                'widget-artificial-intelligence': 'Inteligencia artificial',
            };
            name = widgets_names[id];

            return name;
        },

        _getWidgetsPosition: function(){
            this._getNumRows();
            this.disabled = [];

            let success = (response) => {          
                for(let i=0; i < response.data.length; i++){
                    if(response.data[i].configuration.length == 0 || response.data[i].configuration.enabled == true){
                            let pos = parseInt(response.data[i].position);
                            if(response.data[i].position >= 3){                            
                                let x = ((pos + 1) % this.col_num) ;
                                let y = parseInt(parseInt(pos / this.col_num) + 1)* 6;
                                let id = response.data[i].id.toString().replaceAll("-","_");
                                var position = '';
                                position = {"x": x,"y": y,"w":1,"h":6,"i": id, static: this.static_widgets};
                                this.layout.push(position);
                            }
                            else{
                                    var y = '';
                                    var x = '';
                                    if(this.static_widgets == true){
                                        y = parseInt(pos + 1 / this.col_num) * 6;
                                        x = (pos + 1) % this.col_num;     
                                    }
                                    else{
                                        y = parseInt(pos / this.col_num) * 6;
                                        x = pos % this.col_num;
                                    }
                                                                       
                                    let id = response.data[i].id.toString().replaceAll("-","_"); 
                                    let position = {"x": x,"y": y,"w":1,"h":6,"i": id, static: this.static_widgets};
                                    this.layout.push(position);
                                }                                
                            }
                    else if(response.data[i].configuration.enabled == false){
                        let id = response.data[i].id.toString().replaceAll("-","_");
                        if(response.data[i].id == "widget-directionality"){
                          let name = this._getWidgetName(response.data[i].id);
                          let posicion = {"x": 0,"y": 0,"w":1,"h":7,"i": id, static: this.static_widgets, "name": name};
                          this.disabled.push(posicion); 
                        }
                        else{
                           let name = this._getWidgetName(response.data[i].id); 
                           let posicion = {"x": 0,"y": 0,"w":1,"h":6,"i": id, static: this.static_widgets, "name": name}
                           this.disabled.push(posicion);     
                        }                      
                        
                    }
                                       
                }  

            this._positionCalculated();          
            };

            let url = "/api/v1/widgets/list";

            if (!this.position_calculated) {
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler('215', 'Error recuperando lista de widgets.');

                this._getAPICall(url, successHandler, failureHandler);
            }
        },

        _alignWidgets: function(){
            let array =  JSON.parse(JSON.stringify(this.layout));

            for(let i=0; i < array.length; i++){
                if(this.layout[i].i != "Add"){
                    if(i+1 != array.length){
                        if(this.layout[i + 1].i == 'Add'){
                            if(this.layout[i].x + 2 == this.col_num){
                                this.layout[i].y = this.layout[i].y + 6;
                                this.layout[i].x = 0; 
                            }
                            else{
                               this.layout[i].x = this.layout[i].x + 1;      
                            }                                                       
                        }else{
                            if(this.layout[i].x + 1== this.col_num){
                                this.layout[i].y = this.layout[i].y + 6;
                                this.layout[i].x = 0;
                            }
                            else{
                               this.layout[i].x = this.layout[i].x + 1;      
                            }                            
                        }
                        
                        
                    }
                    else{                        
                        if(this.layout[i].x + 1 == this.col_num){
                            this.layout[i].y = this.layout[i].y + 6;
                            this.layout[i].x = 0;
                        }
                        else{
                            this.layout[i].x = this.layout[i].x + 1;      
                        }
                    }
                }
            }
        },
}
}
</script>

<style scoped>
.widget {
    background-color: var(--color-surface) !important;
    border: 1px solid var(--color-outline) !important;
}

.widget span.material-icons {
    color: var(--color-text-secondary) !important;
}

.widget p, .widget span {
    color: var(--color-text-primary) !important;
}

.widget h4, .platinium-title {
    color: var(--color-highlight) !important;
}

.widget span.mdc-button__label {
    color: white !important;
}

.disabled-info {
    color: var(--color-text-secondary);
    padding: 10px;
}

.container{
    background-color: var(--color-background);
    display: grid;
}
.widgets-disabled{
    float: left;
    width: auto;
    height: auto;
    background: #00add9;
    margin-left: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 1px 5px #0f0f1a;
  }
  .widgets-disabled:hover{
    box-shadow: 0px 1px 7px #0f0f1a;
  }
  .disable-widgets-container{
    background-color: var(--color-surface);
    border: 1px solid var(--color-outline) !important;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: 0px 3px 5px #00000040; 
    margin-top: 5px;
    margin-top: 10px !important;
    border-radius: 5px;
  }
  .widgets-container{
    margin: auto !important;
  }
.vue-grid-layout {
    background-color: var(--color-background);
}
.vue-grid-item.vue-grid-placeholder {
    background: red;
    opacity: 1;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;    
}  
.vue-grid-item.vue-grid-placeholder {
    background: lightgrey !important;
    border: solid black !important;
    border-width: 3px !important;
    border-style: dashed !important;
}
.container.vue-grid-item.vue-grid-placeholder {
    background: green !important;
}
.vue-grid-item .resizing {
    opacity: 0.9;
}
.vue-grid-item .static {
    background: #cce;
}
.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}
.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}
.vue-grid-item .minMax {
    font-size: 12px;
}
.vue-grid-item .add {
    cursor: pointer;
}
.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}
.iframe-container{
    height:100% !important;
}

.help-pop-up{
    position: fixed;
    border: 5px solid white;
    background-color: white;
    width: 500px;
    height: 400px;
    top: 30%;
    left: 35%;
    z-index: 2;
}

.close-div{
    position: fixed;
    width: 1407%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    margin-left: -150px;
}

@media(min-width:450px){
    .disable-widgets-container{
      min-width:96.5% !important;
      width: 96.5%;
      margin: auto;
      top: 10px;
    }
    .draggable-container{
      text-align:center;
    }
  }
  @media(max-width:450px){
    .disable-widgets-container{
      min-width:87% !important;
      max-height: 100% !important;
      top: 10px;
    }
    .draggable-container{
      display:inline-grid;
      text-align:center;
      margin-left: 10px;
      max-height: 100% !important;
    }
    .help-pop-up{
      position: fixed;
      border: 5px solid white;
      background-color: white;
      width: 100%;
      height: 400px;
      top: 30%;
      left: 0;
      z-index: 2;
    }
  }

  .container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
       
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      max-width: 700px;
      width: 700px;
    }
  }

  @media screen and (max-width: 768px) {
    .container-image img {
      max-width: 315px;
    }
  }

  .text-centeR {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

    .not-allowed-widgets-container{
        text-align: center;
        margin-top: 50px;
    }
  
    .link-to-store{
        color: #35add8 !important;
    }

    .not-allowed-widgets-title{
        color: #FF9B00;
        font-weight: bold;
    }
</style>

