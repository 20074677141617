<template>
  <div id="favorite-search" class="widget">
    <div class="draggable">
      <div class="favorite-search-top">
        <h4 class="favorite-search-title">Búsquedas favoritas</h4>
        <div class="favorite-search-top-box">
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="widgets-container">
      <SelectOutline
        :helper_select_permission="false"
        :label_name="label_name"
        :options="options"
        :component_class="component_class"
        :helper_text_select="helper_text_select"
        @_emit_method_order_select="_selectValue"
      />

      <Button
        @_emit_method_button="_goToStockFinder()"
        :button_name="button_name_list"
        :button_key="button_key_list"
        :button_icon="button_icon_list"
        :button_icon_permission="false"
        :button_icon_id="button_icon_id_list"
      />

      <Button
        @_emit_method_button="_goToDynamicStockChart()"
        :button_name="button_name_graphic"
        :button_key="button_key_graphic"
        :button_icon="button_icon_graphic"
        :button_icon_permission="false"
        :button_icon_id="button_icon_id_graphic"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import Button from "@/components/helpers/Button.vue";

export default {
  mixins: [APICaller],
  name: "FavoriteSearch",
  components: {
    SelectOutline,
    Button,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [],
      component_class: "favorite_search",
      helper_text_select: "",
      default_value: "last_year",
      selected_search: "",

      button_name_list: "Buscar en el LISTADO DE VALORES",
      button_key_list: "widget_list",
      button_icon_list: "",
      button_icon_id_list: "widget_list",

      button_name_graphic: "Buscar en el GRAFICADOR DINÁMICO",
      button_key_graphic: "widget_graphic",
      button_icon_graphic: "",
      button_icon_id_graphic: "widget_graphic",
      widget_name: {
        id: "widget-favorite-search",
        name: "Búsquedas favoritas",
      },

      url_iframe:
        "https://player.vimeo.com/video/187302851?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre búsquedas favoritas",
    };
  },
  created() {
    this._getSearchOptions();
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _goToStockFinder: function() {
      setTimeout(() => {
        this._changeFinder();
      }, 100);
      this.$router.push("/app/graficador-dinamico/?mode=list");
    },
    _goToDynamicStockChart: function() {
      if (this.selected_search != "") {
        setTimeout(() => {
          this._changeFinder();
        }, 100);
        this.$router.push("/app/graficador-dinamico/?=mode=gd");
      }
    },
    _getSearchOptions() {
      let success = (response) => {
        var obj_keys = Object.keys(response.data);

        this.selected_search = obj_keys[0];

        var obj_values = Object.values(response.data);

        for (var i = 0; i < obj_keys.length; i++) {
          if (obj_keys[i] != "last_search") {
            var obj = {
              name: obj_keys[i],
              value: obj_values[i],
            };
            this.options.push(obj);
          }
        }
      };

      let url = "/api/v1/widgets/get-favorite-search-options";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "142",
        "Error cargando opciones de búsqueda favoritas."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    ...mapMutations(["setRechargeState"]),

    _changeFinder: function() {
      let success = () => {
        this.setRechargeState();
        this._closeSearchWindow();
      };

      let failure = () => {};

      let url = "/api/v1/stock-finder/change-finder/" + this.selected_search;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "143",
        "Error cambiando la búsqueda."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _selectValue(value) {
      this.selected_search = value[0];
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.favorite-search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.favorite-search-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.favorite-search-text {
  font-size: 14px;
  line-height: 22px;
}

.mdc-button {
  margin: 10px 0;
  width: 100%;
}
.draggable {
  cursor: move;
}
</style>
