<template>
  <div id="best-sectors" class="widget">
    <div class="draggable">
      <div class="best-sectors-top">
        <h4 class="best-sectors-title">Sectores rentables</h4>
        <div class="best-sectors-top-box">
          <a class="best-sectors" :href="gd_url + value_selected">GD</a>
          <a class="best-sectors" href="/app/directionality"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div v-if="loading"></div>
    <div v-else class="widgets-container">
      <SelectOutline
        :helper_select_permission="false"
        :label_name="label_name"
        :options="options"
        :component_class="component_class"
        :helper_text_select="helper_text_select"
        :default_value="default_value"
        @_emit_method_order_select="_selectValue"
      />
      <!--<SelectOutline :helper_select_permission="false" :label_name="label_name" :options="options" :component_class="component_class" :helper_text_select="helper_text_select" :default_value="default_value" />-->
      <TableCustom :table_header="table_header" :table_body="table_body" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import TableCustom from "@/components/helpers/Table_custom.vue";

export default {
  mixins: [APICaller],
  name: "BestSectors",
  components: {
    SelectOutline,
    TableCustom,
  },
  data() {
    return {
      gd_url: "",
      label_name: "Selecciona",
      options: [
        { name: "last_month", value: "Último mes" },
        { name: "last_three_months", value: "Últimos 3 meses" },
        { name: "last_six_months", value: "Últimos 6 meses" },
        { name: "last_year", value: "Último año" },
      ],
      component_class: "best_sectors",
      helper_text_select: "",
      default_value: "last_month",

      value_selected: "last_month",

      table_header: ["Sector", "Rentabilidad"],
      table_body: [],
      widget_name: { id: "widget-best-sectors", name: "Mejores sectores" },
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/305716774?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre mejores sectores",
    };
  },
  mounted() {
    this.gd_url =
      this.$detectEnvironment() + "/app/graficador-dinamico/best-sectors/";

    this._getBestSectors();

    var best_sectors = setInterval(() => {
      if (this.table_body != []) {
        this.loading = false;
        clearInterval(best_sectors);
      }
    }, 200);
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    _getBestSectors() {
      let success = (response) => {
        let bestSectors = response.data;
        Object.entries(bestSectors).forEach((e) => {
          var obj = {};
          obj["a"] = e[1].sector;
          obj["b"] = e[1].profitability;
          this.table_body.push(obj);
        });
      };

      let url = "/api/v1/widgets/get-best-sectors/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "139",
          "Error cargando mejores sectores."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },

    _selectValue(value) {
      if (value != undefined) {
        this.value_selected = value[0];
        this.table_body = [];
        this._getBestSectors();
      }
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.best-sectors-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-sectors-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.best-sectors-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
