<template>
  <div id="masterclass-overview" class="widget">
    <div class="draggable">
      <div class="masterclass-overview-top">
        <h4 class="masterclass-overview-title">
          Resumen de emisiones en directo
        </h4>
        <div class="masterclass-overview-top-box">
          <a class="curve-capital" href="/app/videos"
            ><span class="material-icons blue">launch</span></a
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="masterclass-gif">
      <a :href="urlLink" target="_blank"
        ><img class="image-master" :src="overview_picture"
      /></a>
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import { mapGetters } from "vuex";
export default {
  mixins: [APICaller],
  name: "MasterclassOverview",
  components: {},
  data() {
    return {
      urlLink: "",
      overview_picture: "",
      widget_name: {
        id: "widget-master-class-overview",
        name: "Resumen de emisiones en directo",
      },
    };
  },
  mounted() {
    this.urlLink = this.$detectEnvironment() + "/app/videos";
    this._getMasterclassGIF();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _getMasterclassGIF() {
      let success = (response) => {
        if (response.data.value == 0) {
          //if (this.is_mobile == true) {
          //this.overview_picture = '/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview_seen_movil.gif';
          //}
          //else {
          this.overview_picture =
            process.env.VUE_APP_API +
            "/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview_seen.gif";
          //}
        } else {
          //if (this.is_mobile == true) {
          //this.overview_picture = '/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview_movil.gif';
          //}
          //else {
          this.overview_picture =
            process.env.VUE_APP_API +
            "/sites/all/themes/Porto_sub/widgets/masterclass_overview/master_class_overview.gif";
          //}
        }
      };

      let url = "/api/v1/widgets/masterclass-overview";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "146",
        "Error cargando información de masterclass."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }

  .image-master {
    width: 100%;
    height: 80%;
  }
}
.image-class {
  height: 280px;
  width: 420px;
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.masterclass-overview-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.masterclass-overview-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}
.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.masterclass-overview-text {
  font-size: 14px;
  line-height: 22px;
}
.masterclass-gif {
  height: 280px;
  width: auto;
  overflow: hidden;
}
.draggable {
  cursor: move;
}
</style>
