<template>
  <div id="platinum-values" class="widget">
    <div class="draggable">
      <div class="platinium-top">
        <h4 class="platinium-best-values-title">Mejores valores platino</h4>
        <div class="platinium-top-box">
          <a class="platinium-text" :href="platinum_url">GD</a>
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div v-if="_g_UserFeatures.permissions.platinum_values_widget">
      <VueSlickCarousel :draggable="true" :arrows="true">
        <div class="scroll" id="div-eu">
          <div class="platinium-title">Europe</div>
          <div v-for="(item, index) in this.best_values_eur" :key="index">
            <hr />
            <div class="align-content">
              <p class="nom-size">{{ item.nom }}</p>
              <p class="ticker-size">{{ item.ticker }}</p>
              <p class="number-size">
                {{ (item.platinum_compatibility * 100).toFixed(2) }}
                <br />
                <span class="text-small">puntos</span>
              </p>
            </div>
          </div>
        </div>
  
        <div v-if="this.is_dragging" class="scroll block active" id="div-usa">
          <div class="platinium-title">USA</div>
          <div v-for="(item, index) in this.best_values_usd" :key="index">
            <hr />
            <div class="align-content">
              <p class="nom-size">{{ item.nom }}</p>
              <p class="ticker-size">{{ item.ticker }}</p>
              <p class="number-size">
                {{ (item.platinum_compatibility * 100).toFixed(2) }}
                <br />
                <span class="text-small">puntos</span>
              </p>
            </div>
          </div>
        </div>
  
        <div v-if="this.is_dragging" class="scroll block active" id="div-resto">
          <div class="platinium-title">Resto del mundo</div>
          <div v-for="(item, index) in this.best_values_other" :key="index">
            <hr />
            <div class="align-content">
              <p class="nom-size">{{ item.nom }}</p>
              <p class="ticker-size">{{ item.ticker }}</p>
              <p class="number-size">
                {{ (item.platinum_compatibility * 100).toFixed(2) }}
                <br />
                <span class="text-small">puntos</span>
              </p>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div v-else>
      <p>Su plan actual no tiene acceso a esta herramienta.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  mixins: [APICaller],
  name: "PlatiniumBestValues",
  components: {
    VueSlickCarousel,
  },
  props: {
    dragging: { type: Boolean },
  },
  data() {
    return {
      platinum_url: "",
      best_values: [],
      best_values_eur: [],
      best_values_usd: [],
      best_values_other: [],
      widget_name: { id: "widget-platinum-values", name: "Valores Platino" },
      is_dragging: true,

      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },
  mounted() {
    this.platinum_url =
      this.$detectEnvironment() + "/app/graficador-dinamico/platinum";
    this._getBestPlatinumValues();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
    ...mapGetters(["_g_UserFeatures"]),
  },
  created() {
    //this._getBestPlatinumValues();
  },
  methods: {
    _getBestPlatinumValues() {
      let success = (response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].divisa == "EUR") {
            this.best_values_eur.push(response.data[i]);
          } else if (response.data[i].divisa == "EURUSD") {
            this.best_values_usd.push(response.data[i]);
          } else {
            this.best_values_other.push(response.data[i]);
          }
        }
      };

      let url = "/api/v1/stock-finder/best-platinum-values";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "151",
        "Error recuperando valores platino."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  padding: 8px;
  background-color: black;
  padding: 30px;
  height: 330px;
  width: 370px;
  border-radius: 5px;
  padding-top: 0px;
  overflow: hidden;
  position: relative;
}

#platinum-values {
  background-color: #20202B !important;
}

#platinum-values p, #platinum-values span {
  color: white !important;
}

@media (max-width: 450px) {
  .widget {
    padding: 8px;
    background-color: black;
    padding: 30px;
    height: 330px;
    width: calc(85% - 10px);
    border-radius: 5px;
    padding-top: 0px;
    overflow: hidden;
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.scroll {
  overflow-y: scroll;
  height: 250px;
  margin-top: 10px;
}

.platinium-best-values-title {
  color: white;
  font-weight: bold;
  font-size: 17px;
  margin: 0;
}

.material-icons {
  color: white;
}

.material-icons:hover {
  cursor: pointer;
}

.platinium-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
}

.platinium-text {
  color: white;
  text-decoration: none;
}

.platinium-top-box {
  display: flex;
  align-items: center;
}

.platinium-title {
  color: white;
  text-align: center;
  margin: 20px 0 8px;
  font-weight: bold;
}

.align-content {
  color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 11px 0 7px;
}

p {
  color: white;
  margin: 0;
  font-size: 14px;
}

.nom-size {
  width: 30%;
}

.ticker-size {
  width: 25%;
  text-align: end;
}

.number-size {
  width: 25%;
  font-weight: 900;
  text-align: end;
  line-height: 12px;
}

.text-small {
  font-size: 11px;
  margin: 0;
  font-weight: 400;
}

.buttons {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 23%;
  font-weight: bold;

  span {
    color: white;
  }
}

.active {
  opacity: 1;
}

.draggable {
  cursor: pointer;
}
div:-moz-drag-over {
  background-color: none;
}
</style>
