<template>
  <div id="alerts" class="widget">
    <div class="draggable">
      <div class="best-sectors-top">
        <h4 class="best-sectors-title">Alarmas</h4>
        <div class="best-sectors-top-box">
          <a class="best-sectors" href="/app/graficador-dinamico"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div
      v-if="this.alerts.length > 0"
      class="alerts_container widgets-container"
      style="overflow:scroll;"
    >
      <div v-for="alert in alerts" :key="alert.id">
        <div class="pending-alarms-container" v-if="alert.status == '0'">
          <div>
            <div style="display: flex">
              <p class="registry-date" style="margin:2px;">
                {{ alert.created }}
              </p>
              <span
                class="material-icons-outlined"
                style="margin: 8px 6px 0 auto; font-size: 18px; cursor:pointer"
                @click="_deleteAlarm(alert.id)"
                >delete</span
              >
            </div>
            <p class="registry-text">
              Alarma planeada: <strong>{{ alert.ticker_name }}</strong>
              {{ alert.alert_field_string }} {{ alert.alert_mode_string }}
              {{ alert.alert_data }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="widgets-container">
      <p>No tiene ninguna alarma planificada.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: "Alarmas",
  components: {},
  data() {
    return {
      widget_name: { id: "widget-alerts", name: "Alarmas" },
      loading: true,

      alerts: {},

      url_iframe:
        "https://player.vimeo.com/video/305716774?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre alerts",
    };
  },
  mounted() {
    this.initUserInfo();
    var waiting_for_uid = setInterval(() => {
      if (this.uid && this.uid != undefined && this.uid != "") {
        this._getAlarms();
        clearInterval(waiting_for_uid);
      }
    }, 200);
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_User"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _deleteAlarm: function(id) {
      let params = new URLSearchParams();
      params.append("uid", this.uid);
      params.append("alert_id", id);

      let success = (response) => {
        this._getAlarms();
      };

      let failure = () => {};

      let url = "/api/v1/eurekers-alerts/delete";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "309",
        "Error borrando alarma."
      );

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    _getAlarms: function() {
      let params = new URLSearchParams();
      params.append("uid", this.uid);

      let success = (response) => {
        this.alerts = response.data;
      };

      let url = "/api/v1/eurekers-alerts/get-user-alerts";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "310",
        "Error recuperando alarmas."
      );

      this._postAPICall(url, params, successHandler, failureHandler);
    },

    initUserInfo: function() {
      this.uid = this._g_User.uid;
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }

  .alerts_container {
    height: 280px;
  }
}
.alerts_container {
  height: 280px;
}
.alert {
  border: 1px solid #00add8;
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 5px;
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.best-sectors-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-sectors-top-box {
  display: flex;
  align-items: center;
}

.draggable {
  cursor: move;
}

.registry-text {
  margin-top: 0px !important;
}
.pending-alarms-container {
  margin-left: 5px;
  border: 1px solid lightgrey;
  border-left: 5px solid #42a0c9;
  display: block;
  margin-bottom: 10px;
  border-radius: 5px;
}

.registry-date {
  width: 77px;
  height: 24px;

  /* Subtitle 2 */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  letter-spacing: 0.1px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 10px 0 0 15px;
}

.registry-text {
  width: 341px;

  /* Caption */

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* or 133% */

  letter-spacing: 0.4px;

  /* Tema Claro/02. Primary/900 */

  color: #000010;

  padding: 5px 0 15px 15px;
}
</style>
