import { render, staticRenderFns } from "./widget_alerts.vue?vue&type=template&id=4ab3409c&scoped=true&"
import script from "./widget_alerts.vue?vue&type=script&lang=js&"
export * from "./widget_alerts.vue?vue&type=script&lang=js&"
import style0 from "./widget_alerts.vue?vue&type=style&index=0&id=4ab3409c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ab3409c",
  null
  
)

export default component.exports