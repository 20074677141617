<template>
  <div id="ticker-search" class="widget">
    <div class="draggable">
      <div class="ticker-search-top">
        <h4 class="ticker-search-title">Buscar por ticker</h4>
        <div class="ticker-search-top-box">
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div v-if="_g_UserFeatures.permissions.stock_rastreator">
      <InputTextOutline
        @_emit_method_order_input="_getValueFromInputAutocomplete"
        :input_label="input_label"
        :input_helper="input_helper"
        :has_focus="has_focus"
        :icon_class="icon_class"
        :input_key_to_save_user_values="input_key_to_save_user_values"
        :input_class_character_counter="input_class_character_counter"
        :permission="permission"
        :icon_permission="icon_permission"
        :character_counter_permission="character_counter_permission"
        :helper_permission="helper_permission"
        :test_field_is_numeric="test_field_is_numeric"
        :length="length"
        :is_input_textarea="is_input_textarea"
        class="news-ipos-input"
      />
  
      <div
        v-if="this.list_autocomplete.length != 0"
        class="select-autocomplete-options"
      >
        <ul style="text-align: center;">
          <li
            v-for="(ticker, index) in this.list_autocomplete"
            :key="index"
            class="options-autocomplete"
            style="margin-left: -20px;"
            @click="_preprocessTicker(ticker)"
          >
            <span>{{ ticker }}</span>
            <hr />
          </li>
        </ul>
      </div>
    </div>
    <div v-else> 
      <p>Su plan no cuenta con acceso a este widget</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import InputTextOutline from "@/components/helpers/InputTextOutline.vue";

export default {
  mixins: [APICaller],
  name: "TickerSearch",
  components: {
    InputTextOutline,
  },
  data() {
    return {
      widget_name: { id: "widget-ticker-search", name: "Búsqueda de ticker" },
      list_autocomplete: [],
      autocomplete_input: "",
      ticker_selected: "",
      url_to_redirect: "",

      input_key_to_save_user_values: "search-by-ticker",
      permission: true,
      icon_permission: true,
      test_field_is_numeric: false,
      is_input_textarea: false,
      input_helper: "Búsqueda por ticker",
      input_label: "Búsqueda por ticker",
      input_helper_permission: true,
      has_focus: false,
      icon_class: "input-icon-class",
      input_class_character_counter: "input-character-counter-class",
      character_counter_permission: false,
      helper_permission: false,
      length: 32,
    };
  },
  created() {},
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures"]),
  },
  methods: {
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },

    _getValueFromInputAutocomplete: function(value) {
      this.autocomplete_input = value[0];
      this._getAutocomplete();
    },

    _getAutocomplete: function() {
      let success = (response) => {
        this.list_autocomplete = response.data;
      };

      let url = "/blocks/autocomplete/" + this.autocomplete_input;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "159",
        "Error recuperando autocompletado."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _preprocessTicker: function(ticker) {
      let array = ticker.split(" ");
      this.ticker_selected = array[0];
      this.$router.push(
        "/app/graficador-dinamico/ticker/" +
          this.ticker_selected.replace("&", "_and_")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.ticker-search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ticker-search-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.ticker-search-text {
  font-size: 14px;
  line-height: 22px;
}

.mdc-button {
  margin: 10px 0;
  width: 100%;
}
.draggable {
  cursor: pointer;
}

.select-autocomplete-options {
  height: 60%;
  width: 100%;
  overflow-y: scroll;
  background-color: rgb(241, 241, 241);
}

.options-autocomplete:hover {
  background-color: rgb(111, 209, 255);
}
.options-autocomplete {
  width: 100%;
}
</style>
