<template>
  <div id="directionality" class="widget">
    <div class="draggable">
      <div class="directionality-top">
        <h4 class="directionality-title">Direccionalidad</h4>
        <div class="directionality-top-box">
          <a class="best-sectors" href="/app/directionality"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="iframe-container widgets-container">
      <iframe id="iframe-map" frameborder="0" scrolling="no"></iframe>
    </div>
    <div class="widgets-container">
      <SelectOutline
        id="directionality"
        :options="select_options"
        :label_name="label_name"
        :component_class="component_class"
        @_emit_method_order_select="_getValueFromSelectDirectionality"
        :default_value="default_value"
        class="select-term-style"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectOutline from "@/components/helpers/SelectOutline.vue";

export default {
  name: "Directionality",
  components: {
    SelectOutline,
  },
  data() {
    return {
      term_period_selected: "combined",
      label_name: "Selecciona",
      select_options: [
        { name: "weekly", value: "Direccionalidad a corto plazo" },
        { name: "combined", value: "Direccionalidad a medio plazo" },
        { name: "monthly", value: "Direccionalidad a largo plazo" },
      ],
      default_value: "combined",
      component_class: "directionality-select",
      widget_name: { id: "widget-directionality", name: "Direccionalidad" },

      url_iframe:
        "https://player.vimeo.com/video/187303455?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre direccionalidad",
    };
  },
  mounted() {
    this._chargeIframe();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    _getValueFromSelectDirectionality(value) {
      this.term_period_selected = value[0];
      this._chargeIframe();
    },
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
    _chargeIframe: function() {
      var foro = document.getElementById("iframe-map");
      foro.src =
        process.env.VUE_APP_API +
        "/direccionalidad/mapas/pais/" +
        this.term_period_selected;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.directionality-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 25px;
}

.directionality-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.directionality-text {
  font-size: 14px;
  line-height: 22px;
}

#highchart-content {
  height: 330px;
  width: 100%;
}

.iframe-shield {
  background: transparent;
  width: 415px;
  height: 350px;
  z-index: 99999;
  top: 0;
  position: absolute;
  margin-top: 40px;
  display: none;
}

#iframe-map {
  width: 100%;
  height: 300px;
  position: relative;
  float: left;
  border: none;
}
.draggable {
  cursor: move;
}

.select-term-style {
  bottom: 4px !important;
  position: absolute;
}
.directionality-select {
  margin-top: 20px !important;
}
.iframe-container {
  width: 100%;
  position: relative;
  float: left;
  height: 344px;
}
</style>
