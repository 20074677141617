import APICaller from "./APICaller";

export default {
    methods:{
        _getPortfolios: function (){
            let success = response =>{
      
                let datos = response.data;
                datos.forEach((element) =>{
                  let portfolio = {"name": element.idc, "value": element.name}
                  this.portfolio_list.push(portfolio)
                });
              }
              
              let url = '/api/v1/portfolio/list';
              
              const successHandler = new APICaller.SuccessHandler(success);
              const failureHandler = new APICaller.FailureHandler('006', 'Error recuperando carteras.');
              const apiCallConfig = {loadingOverlay: false};

              this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
        },

    }

}