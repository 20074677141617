<template>
  <div id="curve-capital" class="widget">
    <div class="draggable">
      <div class="curve-capital-top">
        <h4 class="curve-capital-title">Curva de capital</h4>
        <div class="curve-capital-top-box">
          <a class="curve-capital" @click="_redirectToPortfolioCurveCapital()"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    
    <div v-if="_g_UserFeatures.permissions.capital_curve">
      <div v-if="loading"></div>
      <div v-else class="widgets-container">
        <SelectOutline
          v-if="this.portfolio_selected[0] != undefined"
          :helper_select_permission="false"
          :label_name="portfolioJson.label_name"
          :options="portfolio_list"
          :component_class="component_class"
          :helper_text_select="portfolioJson.helper_text_select"
          :default_value="this.portfolio_selected[0].name"
          @_emit_method_order_select="_selectValue"
        />
      </div>
  
      <div class="simulador-highchart widgets-container">
        <div id="highchart-content-curve"></div>
      </div>
    </div>
    <div v-else>
      <p>Su plan actual no cuenta con acceso a este widget.</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PortfolioList from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import Highcharts from "highcharts";
import Vue from "vue";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "CurveCapital",
  components: {
    SelectOutline,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [{ name: "default", value: "Default" }],
      helper_text_select: "",
      default_value: "default",

      value_selected: "",

      portfolioJson: PortfolioList,
      portfolio_list: [],
      portfolio_selected: [],
      component_class: "curve_capital",

      series: [
        {
          name: "Capital Ficticio",
          data: [0, 0],
        },
      ],
      widget_name: { id: "widget-curve-capital", name: "Curva de capital" },
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/187303173?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre curva de capital",
    };
  },
  mounted() {
    this._getPortfolios();
    this._defaultPortfolio();

    var capital = setInterval(() => {
      if (this.value_selected != "" && this.portfolio_list != []) {
        this._getCurveCapital();
        this.loading = false;
        clearInterval(capital);
      }
    }, 100);
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_UserFeatures"]),
  },
  created() {},
  methods: {
    _redirectToPortfolioCurveCapital: function(){
      window.location.href = "/app/portfolio/" + this.value_selected + "/capital-curve"
    },
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getCurveCapital() {
      let success = (response) => {
        this.series = response.data.series;
        var addDataCurveCapitalToHighchartInterval = setInterval(() => {
          if(document.getElementById('highchart-content-curve') != null || document.getElementById('highchart-content-curve') != undefined){
            this._addDataCurveCapitalToHighchart();
            clearInterval(addDataCurveCapitalToHighchartInterval);
          }
        }, 100);
      };

      let url = "/api/v1/portfolio/get-capital-curve/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "140",
          "Error cargando curva de capital."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },
    _defaultPortfolio: function() {
      let success = (response) => {
        this.portfolio_selected.push({
          name: response.data.idc,
          value: response.data.name,
        });
        this.value_selected = response.data.idc;
      };

      let url = "/api/v1/portfolio-invest-preferences";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "141",
        "Error cargando preferencias de inversión."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _addDataCurveCapitalToHighchart: function() {
      let self = this;
      Vue.nextTick(function() {
        Highcharts.chart("highchart-content-curve", {
          chart: {
            type: "line",
            zoomType: "x",
            height: 180,
          },
          title: {
            text: "",
          },
          xAxis: {
            type: "datetime",
            dateTimeLabelFormats: {
              year: "%b",
            },
          },
          yAxis: {
            title: "",
          },
          tooltip: {
            shared: true,
            crosshairs: true,
          },
          plotOptions: {
            line: {
              marker: {
                enabled: false,
              },
            },
          },
          series: self.series,
        });
      });
    },

    _selectValue(value) {
      this.value_selected = value[0];
      this._getCurveCapital();
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.curve-capital-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.curve-capital-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.curve-capital-text {
  font-size: 14px;
  line-height: 22px;
}

#highchart-content {
  height: 330px;
  width: 100%;
}
.draggable {
  cursor: move;
}
</style>
