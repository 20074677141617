<template>
  <div id="portfolio-changes" class="widget">
    <div class="draggable">
      <div class="portfolio-changes-top">
        <h4 class="portfolio-changes-title">Cambios en cartera</h4>
        <div class="portfolio-changes-top-box">
          <a class="curve-capital" href="/app/portfolio"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>

    <div v-if="loading"></div>
    <div v-else>
      <SelectOutline
        v-if="this.portfolio_selected[0] != undefined"
        :helper_select_permission="false"
        :label_name="portfolioJson.label_name"
        :options="portfolio_list"
        :component_class="component_class"
        :helper_text_select="portfolioJson.helper_text_select"
        :default_value="this.portfolio_selected[0].name"
        @_emit_method_order_select="_selectValue"
      />
    </div>
    <div class="widgets-container">
      <TableCustom :table_header="table_header" :table_body="table_body" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PortfolioList from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import TableCustom from "@/components/helpers/Table_custom.vue";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "PortfolioChanges",
  components: {
    SelectOutline,
    TableCustom,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [{ name: "default", value: "Default" }],
      component_class: "portfolio_changes",
      helper_text_select: "",
      default_value: "default",
      portfolioJson: PortfolioList,
      portfolio_list: [],
      portfolio_selected: [],
      value_selected: "",
      table_header: ["Alarmas"],
      table_body: [],
      widget_name: {
        id: "widget-portfolio-changes",
        name: "Cambios de cartera",
      },
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/187302500?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre cambios en cartera",
    };
  },
  created() {
    this._getPortfolios();
    this._defaultPortfolio();

    var portfolio = setInterval(() => {
      if (this.value_selected != "") {
        this._getPortfolioChanges();
        this.loading = false;
        clearInterval(portfolio);
      }
    }, 100);
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getPortfolioChanges() {
      this.table_body = [];
      let success = (response) => {
        if (response.data.list != undefined) {
          let bestSectors = response.data.list["#items"];
          Object.entries(bestSectors).forEach((e) => {
            var obj = {};
            obj["a"] = e[1].data.message;
            this.table_body.push(obj);
          });
        }
        if (response.data.message != undefined) {
          let markup = response.data.message["#markup"];
          var obj1 = {};
          obj1["a"] = markup;
          this.table_body.push(obj1);
        }
      };

      let url = "/api/v1/portfolio/alerts/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "152",
          "Error recuperando alertas."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },

    _defaultPortfolio: function() {
      let success = (response) => {
        this.portfolio_selected.push({
          name: response.data.idc,
          value: response.data.name,
        });
        this.value_selected = response.data.idc;
      };

      let url = "/api/v1/portfolio-invest-preferences";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "153",
        "Error recuperando preferencias de inversión."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _selectValue(value) {
      if (value != undefined) {
        this.value_selected = value[0];
        this._getPortfolioChanges();
      }
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: var(--color-surface);
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.portfolio-changes-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.portfolio-changes-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
  cursor: default;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.portfolio-changes-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}

.portfolio-changes-title {
  cursor: pointer;
}
</style>
