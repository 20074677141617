<template>
  <div id="add-widget" class="add-widget widget">
    <div style="height: 100%;" v-html="html_content"></div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
export default {
  mixins: [APICaller],
  name: 'Add',
  data() {
    return {
      html_content: ''
    };
  },
  created() {
    this._getWidgetContent();
  },
  mounted() {
    if(this.is_mobile_device){
      var widget = document.getElementById("add-widget");
      var prop = window.screen.width * 0.90;
      widget.style.width = prop + 'px';
      widget.style.margin = "5px 5px 0px"
    }
  },
  computed:{
    ...mapGetters(['is_mobile_device']),
  },
  methods: {
    _getWidgetContent() {
      let success = response => {
        this.html_content = response.data;
      }

      let url = '/api/v1/widgets/get-advice-content';
      
      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('137', 'Error en la conexión con el widget de consejos.');
      const apiCallConfig = {loadingOverlay: false};

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    }
  }
}
</script>



<style lang="scss" scoped>
    #add-widget img {
      width: 100%;
    }

    #add-widget{
      border-radius: 5px;
      height: 350px;
      width: 420px;
      padding: 5px;
      background-color: var(--color-surface);
    }

    @media(max-width:450px){
      #add-widget {
        border-radius: 5px;
        height: 327px;
        padding: 5px;
        background-color: white;
        width: 420px;
      }

      #add-widget img{
        width: 100%;
        height:100%;
        max-height: 327px;
        max-width: 420px;
      }
    }

    .video-class{
      height: 350px;
      width: 420px;
      overflow-x: hidden;
    }

    #add-widget:hover{
      cursor: pointer;
    }
</style>
