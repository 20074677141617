<template>
  <div id="twin-souls" class="widget">
    <div class="draggable">
      <div class="twin-souls-top">
        <h4 class="twin-souls-title">Almas gemelas</h4>
        <div class="twin-souls-top-box">
          <a class="hot-values" :href="gd_url + twin_index">GD</a>
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>

    <div v-if="loading"></div>
    <div v-else class="widgets-container">
      <div v-if="this.there_are_data">
        <p class="twin-souls-text">
          Aquí puedes ver los usuarios mas afines a ti y algunas entradas que a
          ellos les gusta pero que no has valorado.
        </p>

        <div style="display: flex;">
          <div style="width: 9%;display:flex; flex-direction: column;">
            <div v-for="(twin, twinIndex) in twin_souls" :key="twinIndex" class="twin">
              <div class="twin-souls-icons">
                <span
                  class="material-icons"
                  @mouseover="_changeTwin(twinIndex)"
                  @mouseout="activeTwinIndex=''"
                  :aria-describedby="tooltip_id[twinIndex]"
                  >person</span
                >
              </div>
            </div>
          </div>
          <div style="width: 41%;display:flex; flex-direction: column;">
            <div
              v-for="(twin, twinIndex) in twin_souls"
              :key="twinIndex"
              class="div-exterior"
              :class="{ 'div-exterior-hovered': twinIndex === activeTwinIndex }"
            >
              <div class="div-interior">
                <p class="twin-title">{{ tooltip_title }}</p>
                <hr style="width: 90%; margin-right: 20px;">
                <p class="twin-text">Eurekers desde: {{ twin.extra_info.eurekers_since }}</p>
                <p class="twin-text">Ciudad: {{ twin.extra_info.city }}</p>
                <p class="twin-text">Trabajo: {{ twin.extra_info.job }}</p>
                <p class="twin-text">Nivel de eurekers: {{ twin.extra_info.master_eurekers }}</p>
              </div>
            </div>
          </div>

          <div style="width: 50%;">
            <TableCustom
              :table_header="table_header"
              :table_body="table_body"
              id="twin-souls"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <p>
          Necesitas tener tickers en Carteras o en Favoritos para poder ver
          resultados en esta herramienta.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import TableCustom from "@/components/helpers/Table_custom.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  name: "TwinSouls",
  components: {
    TableCustom,
  },
  data() {
    return {
      twin_souls: [],
      twin_user_selected: [],
      selected_affinity_length: 0,
      selected_affinity_index: false,

      tooltip_id: ["twin1", "twin2", "twin3", "twin4", "twin5"],
      tooltip_title: "Información adicional",
      tooltip_date: "1 de enero",
      tooltip_city: "Valencia",
      tooltip_job: "sdfasdf",
      tooltip_level: "sdfasf",

      table_header: ["Ticker"],
      table_body: [],
      widget_name: { id: "widget-twin-souls", name: "Almas gemelas" },
      there_are_data: false,
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/211289703?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre almas gemelas",
      activeTwinIndex: 8,
      twin_index: 0,
      gd_url: "",
    };
  },
  mounted() {
    this.gd_url =
      this.$detectEnvironment() + "/app/graficador-dinamico/twin-souls/";
    setTimeout(
      function() {
        this._getTwinSouls();
        this.loading = false;
      }.bind(this),
      100
    );
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getTwinSouls() {
      let success = (response) => {
        this.twin_souls = response.data;
        if (this.twin_souls.length != 0) {
          this.there_are_data = true;
          this._changeTwin(0, true);
        }
      };

      let url = "/api/v1/widgets/get-twin-souls";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "160",
        "Error recuperando almas gemelas."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
    _changeTwin: function(index, first_time=false) {
      this.table_body = [];
      this.twin_index = index;
      for (var i = 0; i < this.twin_souls[index].tickers.length; i++) {
        var stock = this.twin_souls[index].tickers[i];
        this.table_body.push({ Ticker: stock.toString() });
      }
      if(!first_time){
        this.activeTwinIndex = index;
      }
    },

    // _changeTwinSelected(index) {
    //   this.twin_user_selected = this.twin_souls[index];
    //   this.selected_affinity_length = this.twin_user_selected.tickers.length;
    //   this.selected_affinity_index = index;
    // }
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: var(--color-surface);
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}
.widget:hover {
  border-top: 2px solid #00add8;
}
.twin-souls-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.twin-souls-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.twin-souls-text {
  font-size: 14px;
  line-height: 22px;
  color: var(--color-text-primary);
}
.twin-souls-icons * {
  color: var(--color-text-primary);
}
.draggable {
  cursor: move;
}
.twin {
  width: 34%;
  float: left;
  margin-top: 5px;
  margin-left: 5%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.div-interior{
  height: 90%;
  width: 90%;
  padding: 15px;
}

.div-exterior{
  height: 200px;
  width: 162px;
  display: none; /* Initially hide the div-exterior */
  
}

.twin-text{
  font-size: 10px;
  color: white;
}

.twin-title{
  font-size: 14px;
  color: white;
}


.div-exterior-hovered {
  display: block; /* Show the div-exterior when hovered */
  background-color: #004556;
}
</style>
