<template>
  <div id="best-bullish-values" class="widget">
    <div class="draggable">
      <div class="best-bullish-top">
        <h4 class="best-bullish-title">Mejores valores alcistas</h4>
        <div class="best-bullish-top-box">
          <a class="best-bullish" :href="gd_url + value_selected">GD</a>
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div v-if="loading"></div>
    <div v-else>
      <SelectOutline
        :helper_select_permission="false"
        :label_name="label_name"
        :options="options"
        :component_class="component_class"
        :helper_text_select="helper_text_select"
        :default_value="default_value"
        @_emit_method_order_select="_selectValue"
      />

      <TableCustom
        :table_header="table_header"
        :table_body="table_body"
        class="widgets-container"
      />
    </div>
  </div>
</template>

<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import TableCustom from "@/components/helpers/Table_custom.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller],
  name: "BestBullishValues",
  components: {
    SelectOutline,
    TableCustom,
  },
  data() {
    return {
      gd_url: "",
      label_name: "Selecciona",
      options: [
        { name: "last_month", value: "Último mes" },
        { name: "last_three_months", value: "Últimos 3 meses" },
        { name: "last_year", value: "Último año" },
      ],
      component_class: "bullish_value",
      helper_text_select: "",
      default_value: "last_year",

      value_selected: "last_year",

      user_id: "",
      user_info: "",
      user_is_pre_eureker: "",
      values_hot: [],
      imgs: [],

      table_header: ["Ticker", "Rentabilidad"],
      table_body: [],
      widget_name: {
        id: "widget-best-bullish-values",
        name: "Mejores valores alcistas",
      },
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/261815307?title=0&byline=0&portrait=0&autoplay=1", //a rellenar, este no es el video que toca
      help_tittle: "Ayuda sobre mejores valores alcistas",
    };
  },
  created() {},
  mounted() {
    this.gd_url =
      this.$detectEnvironment() +
      "/app/graficador-dinamico/best-bullish-values/";

    this._getUID();

    var bullish = setInterval(() => {
      if (this.value_selected != "") {
        this._getBestBullish();
        this.loading = false;
        clearInterval(bullish);
      }
    }, 100);
  },
  computed: {
    ...mapGetters(["is_mobile_device", "_g_User"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getBestBullish() {
      let success = (response) => {
        let datos = response.data;
        Object.entries(datos).forEach((e) => {
          let obj = {};
          obj["ticker"] = e[1].ticker;
          obj["comentario"] = e[1].profitability_interval + " %";
          this.table_body.push(obj);
        });
      };

      let url =
        "/api/v1/stock-finder/best-bullish-values/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "164",
          "Error recuperando valores alcistas."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },

    _getUID() {
      this.user_id = this._g_User.uid;
    },

    _getUserInfo() {
      this.user_info = this._g_User.user_features.pre_eurekers_views;
      this.user_is_pre_eureker = this._g_User.is_pre_eureker;
    },

    /*_getEvenValuesFromTableRow(){

      var table = document.getElementById("table-best-bullish-values");
      var tbody = table.getElementsByTagName("tbody")[0];
      var tr = tbody.getElementsByTagName("tr");
      tr.innerHTML = "<i class=\"material-icons mdc-button__icon\">visibility</i>";

      for(var i = 0; i < tr.length; i+=2){
        this.values_hot.push(tr[i])
        tr[i].innerHTML = "<i class=\"material-icons mdc-button__icon\">visibility</i>";
      }
    },*/

    _selectValue(value) {
      if (value != undefined) {
        this.value_selected = value[0];
        this.table_body = [];
        this._getBestBullish();
      }
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}
.widget:hover {
  border-top: 2px solid #00add8;
}

.best-bullish-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.best-bullish-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}
.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.best-bullish-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
