<template>
  <div id="motivational-sentences" class="widget">
    <div class="draggable">
      <div class="motivational-sentences-top">
        <h4 class="motivational-sentences-title">Citas célebres</h4>
        <div class="motivational-sentences-top-box">
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <img
      class="image-class"
      :src="'https://www.eurekers.com' + motivational_sentence"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins: [APICaller],
  name: "MotivationalSentences",
  components: {},
  data() {
    return {
      motivational_sentence: "",
      widget_name: {
        id: "widget-motivational-sentences",
        name: "Citas célebres",
      },
    };
  },
  mounted() {
    this._getMotivationalSentences();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _getMotivationalSentences() {
      let success = (response) => {
        this.motivational_sentence = response.data;
      };

      let url = "/api/v1/widgets/get-motivational-sentence";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "148",
        "Error recuperando frase motivacional."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
  .image-class {
    height: 90%;
    width: 90%;
  }
}
.image-class {
  height: 280px;
  width: auto;
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.motivational-sentences-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.motivational-sentences-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.motivational-sentences-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
