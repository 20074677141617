<template>
  <div id="forum-search" class="widget">
    <div class="draggable">
      <div class="forum-search-top">
        <h4 class="forum-search-title">Búsqueda de foro</h4>
        <div class="forum-search-top-box">
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>
    <div class="iframe-container widgets-container">
      <iframe
        id="iframe-forum-search"
        frameborder="0"
        scrolling="yes"
        allow-top-navigation-without-user-activation
      ></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Directionality",
  data() {
    return {
      term_period_selected: "combined",
      label_name: "Selecciona",
      select_options: [
        { name: "weekly", value: "Direccionalidad a corto plazo" },
        { name: "combined", value: "Direccionalidad a medio plazo" },
        { name: "monthly", value: "Direccionalidad a largo plazo" },
      ],
      default_value: "combined",
      component_class: "forum-search-select",
      widget_name: { id: "widget-forum-search", name: "Búsqueda de foro" },

      url_iframe:
        "https://player.vimeo.com/video/187303455?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre direccionalidad",
    };
  },
  mounted() {
    this._chargeIframe();
  },
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },
    _getValueFromSelectDirectionality(value) {
      this.term_period_selected = value[0];
      this._chargeIframe();
    },
    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
    _chargeIframe: function() {
      var foro = document.getElementById("iframe-forum-search");
      foro.src = process.env.VUE_APP_API + "/forum/search";
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.forum-search-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.forum-search-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.ticker-search-text {
  font-size: 14px;
  line-height: 22px;
}

.mdc-button {
  margin: 10px 0;
  width: 100%;
}
.draggable {
  cursor: pointer;
}

.select-autocomplete-options {
  height: 60%;
  width: 100%;
  overflow-y: scroll;
  background-color: rgb(241, 241, 241);
}

.options-autocomplete:hover {
  background-color: rgb(111, 209, 255);
}
.options-autocomplete {
  width: 100%;
}
#iframe-forum-search {
  overflow-y: scroll;
  width: 100%;
  height: 280px;
}
</style>
