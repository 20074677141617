<template>
  <div id="open-positions" class="widget">
    <div class="draggable">
      <div class="waiting-values-top">
        <h4 class="waiting-values-title">Posiciones abiertas</h4>
        <div class="waiting-values-top-box">
          <a class="curve-capital" href="/app/portfolio"
            ><span class="material-icons blue">launch</span></a
          >
          <span class="material-icons blue" @click="_sendDataToIframe()"
            >help</span
          >
          <span @click="_closeWidget()" class="material-icons">close</span>
        </div>
      </div>
    </div>

    <SelectOutline
      v-if="this.portfolio_selected[0] != undefined"
      :helper_select_permission="false"
      :label_name="portfolioJson.label_name"
      :options="portfolio_list"
      :component_class="component_class"
      :helper_text_select="portfolioJson.helper_text_select"
      :default_value="this.portfolio_selected[0].name"
      @_emit_method_order_select="_selectValue"
    />
    <div v-if="this.there_are_data" class="widgets-container">
      <TableCustom :table_header="table_header" :table_body="table_body" />
    </div>
    <div v-else class="widgets-container">
      <p>No tienes posiciones abiertas en esta cartera.</p>
    </div>
  </div>
</template>

<script>
import GetPortfolios from "@/components/codeComponents/getPortfolios.js";
import portfolios from "@/views/configs/portfolio.json";
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from "@/components/helpers/SelectOutline.vue";
import TableCustom from "@/components/helpers/Table_custom.vue";
import { mapGetters } from "vuex";

export default {
  mixins: [APICaller, GetPortfolios],
  name: "WaitingValues",
  components: {
    SelectOutline,
    TableCustom,
  },
  data() {
    return {
      label_name: "Selecciona",
      options: [{ name: "default", value: "Default" }],
      component_class: "open_positions",
      helper_text_select: "",
      default_value: "default",
      portfolioJson: portfolios,
      value_selected: "",
      table_header: ["Ticker", "Entrada", "Acciones", "Montante", "Riesgo"],
      table_body: [],
      widget_name: { id: "widget-open-positions", name: "Posiciones abiertas" },
      there_are_data: false,
      portfolio_list: [],
      portfolio_selected: [],
      loading: true,

      url_iframe:
        "https://player.vimeo.com/video/187303863?title=0&byline=0&portrait=0&autoplay=1",
      help_tittle: "Ayuda sobre valores en espera",
    };
  },
  created() {
    this._getPortfolios();
    this._defaultPortfolio();

    var waiting = setInterval(() => {
      if (this.value_selected != "") {
        this._getOpenPositions();
        clearInterval(waiting);
      }
    }, 100);
  },
  mounted() {},
  computed: {
    ...mapGetters(["is_mobile_device"]),
  },
  methods: {
    _sendDataToIframe: function() {
      this.$emit("url_iframe", this.url_iframe);
      this.$emit("help_tittle", this.help_tittle);
      this.$emit("load_iframe");
    },

    _getOpenPositions() {
      let success = (response) => {
        let open_positions = response.data;

        if (open_positions.length > 0) {
          this.there_are_data = true;
          Object.entries(open_positions).forEach((e) => {
            var obj = {};
            obj["a"] = e[1].ticker;
            obj["b"] = e[1].entry_type_name;
            obj["c"] = e[1].instrument_number;
            obj["d"] = e[1].amount + "€";
            obj["e"] = e[1].risk;
            this.table_body.push(obj);
          });
        } else {
          this.there_are_data = false;
        }
      };

      let url = "/api/v1/portfolio/open_positions/" + this.value_selected;

      if (this.value_selected && this.value_selected != "") {
        const successHandler = new APICaller.SuccessHandler(success);
        const failureHandler = new APICaller.FailureHandler(
          "149",
          "Error recuperando posiciones abiertas."
        );
        const apiCallConfig = { loadingOverlay: false };

        this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
      }
    },
    _defaultPortfolio: function() {
      let success = (response) => {
        this.portfolio_selected.push({
          name: response.data.idc,
          value: response.data.name,
        });
        this.value_selected = response.data.idc;
      };

      let url = "/api/v1/portfolio-invest-preferences";

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler(
        "150",
        "Error recuperando preferencias de inversión."
      );
      const apiCallConfig = { loadingOverlay: false };

      this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
    },

    _selectValue(value) {
      this.value_selected = value[0];
      this.table_body = [];
      this._getOpenPositions();
    },

    _closeWidget: function() {
      this.$emit("widget_name", this.widget_name);
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  border-radius: 5px;
  height: 350px;
  width: 420px;
  padding: 5px;
  background-color: white;
  position: relative;
}

@media (max-width: 450px) {
  .widget {
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    width: calc(100% - 10px);
  }
}

.widget:hover {
  border-top: 2px solid #00add8;
}

.waiting-values-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.waiting-values-top-box {
  display: flex;
  align-items: center;
}

.material-icons {
  margin-left: 5px;
}

.material-icons:hover {
  cursor: pointer;
}

h4 {
  color: #00add8;
}

a {
  text-decoration: none;
  color: #00add8;
  font-weight: bold;
}

.blue {
  color: #00add8;
}

.blue:hover {
  border-bottom: 1px solid #00add8;
}

.waiting-values-text {
  font-size: 14px;
  line-height: 22px;
}
.draggable {
  cursor: move;
}
</style>
