<template>
    <div v-if="loaded1 != false && loaded2 != false && loaded3 != false" id="best-portfolio" class="widget">
        <div class="draggable">
            <div class="main-container">
                <h4 class="title">
                    Carteras más rentables
                </h4>
                <div class="portfolios-container">
                    <div class="portfolio" v-for="(portfolio, index) in portfolios">
                        <div v-on:click="_openPortfolio(index)" class="portfolio-name"
                            :class="{ 'blue-class': portfolio_open_index == index }">
                            <div class="name-main-container">
                                <div class="portfolio-label">{{ (index + 1) + '. ' + names[index] }}</div>
                                <div class="portfolio-profitability">Rentabilidad: {{ parseInt(portfolio.rentability) }}%
                                </div>
                            </div>

                            <div v-if="available_portfolios.includes(index)" class="arrow">
                                <span v-if="portfolio_open_index != index" class="material-icons-outlined">
                                    navigate_before
                                </span>
                                <span v-else class="material-icons-outlined">
                                    expand_more
                                </span>
                            </div>
                            <div v-else class="arrow">
                                <span class="material-icons-outlined">
                                    lock
                                </span>
                            </div>
                        </div>
                        <div v-if="portfolio_open_index == index" class="portfolio-tickers-container">
                            <div class="capital-info">
                                <div class="centered-container">
                                    <div class="charge-bar-container">
                                        <div class="charge-bar"
                                            :style="{ width: (1 - portfolio.total_amount / portfolio.ficticious_capital) * 100 + '%' }">
                                        </div>
                                    </div>
                                    <div class="efective_invested">
                                        <span>{{ Math.max(((1 - (portfolio.total_amount / portfolio.ficticious_capital)) * 100).toFixed(0), 0) }}% de Efectivo</span>
                                        <span class="invested">{{ (portfolio.total_amount / portfolio.ficticious_capital *
                                            100).toFixed(0) }}% Invertido</span>
                                    </div>
                                </div>
                            </div>
                            <div class="tickers-container">
                                <div v-for="ticker in portfolio.ticker" class="tickers">
                                    <hr>
                                    <div class="ticker-info-container">
                                        <span class="ticker-name-container">{{ ticker.ticker }}</span>
                                        <span
                                            :class="{ 'blue-type': ticker.type == 'STOCK', 'green-type': ticker.type == 'CFD', 'yellow-type': ticker.type == 'DIVISA' }">{{
                                                ticker.type }}</span>
                                        <span class="percentage">{{ (ticker.amount / portfolio.ficticious_capital *
                                            100).toFixed(1) }}%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import APICaller from "@/components/codeComponents/APICaller.js";
import { deviceDetect } from "mobile-device-detect";
export default {
    mixins: [APICaller],
    name: 'BestPortfolios',
    data() {
        return {
            names: ['Andrómeda', 'Casiopea', 'Perseo', 'Osa Mayor', 'Osa Menor'],
            portfolios: [],
            portfolio_open_index: -1,
            chargePercentage: 50,
            loaded1: false,
            loaded2: false,
            loaded3: false,
            available_portfolios: null,
        };
    },
    methods: {
        _openPortfolio: function (index) {
            if (this.available_portfolios.includes(index)) {
                if (this.portfolio_open_index == index) {
                    this.portfolio_open_index = -1;
                }
                else {
                    this.portfolio_open_index = index;
                }
            }
        },

        _getDailyBalance: function (idc, index) {
            let success = (response) => {
                this.portfolios[index].ficticious_capital = parseFloat(response.data[0].fictitious_capital);
                this.loaded3 = true;
            }

            let url = "/api/v1/portfolio/dailybalance/" + idc;

            if (idc != "") {
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler(
                    "1397",
                    "Error cargando mejores carteras."
                );
                const apiCallConfig = { loadingOverlay: false };

                this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
            }
        },

        _getPortfolioInfo: function (idc, index) {
            let success = (response) => {
                let portfolio_info = response.data;
                this.portfolios[index].real_capital = parseFloat(portfolio_info.real_capital);
                this.portfolios[index].total_amount = portfolio_info.open_positions.total_amount;
                this.loaded2 = true;
            };

            let url = "/api/v1/portfolio/" + idc;

            if (idc != "") {
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler(
                    "1397",
                    "Error cargando mejores carteras."
                );
                const apiCallConfig = { loadingOverlay: false };

                this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
            }

            return true;
        },



        _getOpenPositionsInfo: function (idc, index) {
            let success = (response) => {
                let portfolio_info = response.data;
                this.portfolios[index].ticker = [];
                for (let i = 0; i < portfolio_info.length; i++) {
                    this.portfolios[index].ticker.push({ 'ticker': portfolio_info[i].ticker, 'amount': portfolio_info[i].amount, 'type': portfolio_info[i].instrument_type });
                }
                this.loaded1 = true;
            };

            let url = "/api/v1/portfolio/open_positions/" + idc;

            if (idc != "") {
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler(
                    "1396",
                    "Error cargando mejores carteras."
                );
                const apiCallConfig = { loadingOverlay: false };

                this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
            }

            return true;

        },

        _getBestPortfolios: function (number) {
            let success = (response) => {
                let portfolios = response.data;
                for (let i = 0; i < portfolios.length; i++) {
                    this.portfolios.push({ 'idc': portfolios[i].idc, 'rentability': portfolios[i].rentability });
                    this._getOpenPositionsInfo(portfolios[i].idc, i);
                    this._getPortfolioInfo(portfolios[i].idc, i);
                    this._getDailyBalance(portfolios[i].idc, i);
                }
            };

            let url = "/api/v1/eurekers-best_portfolios/get/" + number;

            if (number != "") {
                const successHandler = new APICaller.SuccessHandler(success);
                const failureHandler = new APICaller.FailureHandler(
                    "1397",
                    "Error cargando mejores carteras."
                );
                const apiCallConfig = { loadingOverlay: false };

                this._getAPICall(url, successHandler, failureHandler, apiCallConfig);
            }
        }
    },
    mounted() {
        var user = this._g_User;
        var permissions = user.roles;

        const roleValues = {
            Oro: [0, 1, 2, 3, 4],
            Platino: [0, 1, 2, 3, 4],
            Plata: [2, 3, 4],
            Bronce: [4]
        };

        const roles = Object.values(permissions);

        for (const role of roles) {
            if (roleValues.hasOwnProperty(role)) {
                var portfolioAvailableRole = roleValues[role];
                break;
            }
        }

        this.available_portfolios = portfolioAvailableRole;

        var portfoliosDone = setInterval(() => {
            if (this.portfolios != []) {
                this._getBestPortfolios(5);
                clearInterval(portfoliosDone);
            }
        }, 250);
    },

    computed: {
        ...mapGetters(["_g_User"]),
    },
};

</script>
<style lang="scss" scoped>
.widget {
    border-radius: 5px;
    height: 350px;
    width: 420px;
    padding: 5px;
    background-color: white;
    position: relative;
    overflow: scroll;
}

@media (max-width: 450px) {
    .widget {
        border-radius: 5px;
        padding: 5px;
        background-color: white;
        width: calc(100% - 10px);
    }
}

.title {
    color: #00add8;
}

.widget:hover {
    border-top: 2px solid #00add8;
}

.portfolio-profitability {
    margin-left: 15px;
}

.portfolio-name {
    margin: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 37px;
}

.blue-class {
    color: #00add8;
}

.portfolio-label {
    font-size: 14px;
    font-weight: bold;
}

.charge-bar-container {
    width: 280px;
    height: 18px;
    background-color: #3a3842;
    border-radius: 3px;
    border: 3px solid #3a3842;
    overflow: hidden;
}

.charge-bar {
    height: 100%;
    background-color: #ffffff;
}

.portfolio-tickers-container {
    background-color: #f4f4f8;
    padding-top: 15px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 15px;
}

.tickers-container {
    margin-top: 20px;
}

.arrow {
    margin-left: 150px;
}

.efective_invested {
    font-size: 9px;
}

.capital-info {
    text-align: center;
}

.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.invested {
    margin-left: 128px;
}

.blue-type {
    background-color: #71b5df;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.yellow-type {
    background-color: #ddb57d;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.green-type {
    background-color: #63ab76;
    border-radius: 6px;
    font-size: 11px;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    color: white;
    width: 33px;
    text-transform: uppercase;
    text-align: center;
}

.ticker-info-container {
    display: flex;
    justify-content: space-between;
}

.ticker-name-container {
    width: 30px;
}

.percentage {
    width: 47px;
}
</style>